import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  Image,
  Container,
  Button,
  Center,
  Progress,
  useToast,
} from "@chakra-ui/react";
import toastifyFile from "../../external-libraries/React-toastify/index";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import axoisConfigUtil from "../../../utils/AxoisConfigUtil";
import upload from "../../../assets/images/dashboard/loading/before-uploading.png";
import { t } from "i18next";
import videosConstants from "../../../constants/VideosConstants";
import { Reorder, useDragControls } from "framer-motion";
import DragedItem from "./DragedItem";
import bytesToSize from "../../../helpers/bytesToSize";

export default function FileUploader({ isOpen, onClose }) {
  const [uploading, setUploading] = useState(false);
  const [onError, setOnError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successFiles, setSuccessFiles] = useState([]);
  const [totalUplaodedFiles, setTotalUplaodedFiles] = useState(0);
  const [reordedList, setReorededList] = useState([]);
  const toast = useToast();
  const dragControls = useDragControls();

  let navigate = useNavigate();

  let partList = [];

  function createNewProjectWithParts() {
    setUploading(true);
    const files = reordedList;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/projects`,
        [],
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then((res) => {
        for (let i = 0; i < files.length; i++) {
          let partFormData = new FormData();
          partFormData.append("project_id", res.data.project.id);
          partFormData.append("file", files[i]);

          partList.push(partFormData);
        }
        sendPart();
      })
      .catch((error) => console.log(error));
  }

  function sendPart() {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/parts`,
        partList[0],
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then((res2) => {
        axios.put(
          `${process.env.REACT_APP_API_URL}/parts/${res2.data.part.id}`,
          {
            name: partList[0].get("file").name,
          },
          axoisConfigUtil.getHeadersWithLangAndToken()
        );
        setTotalUplaodedFiles((prev) => prev + 1);
        if (partList.length === 1) {
          toastifyFile.successNotify("created success");
          setUploading(false);
          navigate(partList[0].get("project_id"));
        }

        partList.shift();

        if (partList.length > 0) {
          sendPart();
        }
      })
      .catch((error) => {
        if (partList.length === 1) {
          setUploading(false);
          navigate(partList[0].get("project_id"));
        }

        setErrorMsg(error?.response?.data?.message || null);
        // setOnError(true);
        // setUploading(false);

        partList.shift();
        if (partList.length > 0) {
          sendPart();
        }
      });
  }

  const onDrop = useCallback((accepted, rejections, e) => {
    if (accepted.length) {
      for (let i = 0; i < accepted.length; i++) {
        if (reordedList.length > 4) {
          break;
        }
        let reordedListCupy = reordedList;
        reordedListCupy.push(accepted[i]);
        setReorededList(reordedListCupy);
      }

      setSuccessFiles(accepted);
    }
    if (rejections.length) {
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    fileRejections,
    inputRef,
  } = useDropzone({
    onDrop,
    accept: videosConstants.ALLOWED_VIDEO_TYPES,
    // maxFiles: videosConstants.MAX_FILES,
    multiple: true,
    maxSize: videosConstants.MAX_SIZE,
    noClick: reordedList.length > 4 ? true : false, // work around :(
  });

  function onModalClose() {
    setOnError(false);
    setErrorMsg(null);
    setUploading(false);
    onClose();
    // inputRef.current.value = "";

    setReorededList([]);
    setSuccessFiles([]);
    setTotalUplaodedFiles(0);
  }

  // useEffect(() => {
  //   if (successFiles) {
  //     setReorededList([...reordedList, ...successFiles]);
  //   }
  // }, [successFiles]);

  // useEffect(() => {
  //   if (fileRejections) {
  //     toast({
  //       title: "error",
  //       description: "Uploading file error",
  //       duration: 8000,
  //       render: () => (
  //         <Box color='black' p={1} rounded='md' bg='blackAlpha.300'>
  //           <UnorderedList>
  //             {fileRejections.map((file) => (
  //               <ListItem key={file.file.name}>
  //                 {file.errors[0].code === "file-too-large"
  //                   ? t("emptyState.errorMessageMaxSize")
  //                   : file.errors[0].code === "file-invalid-type"
  //                     ? t("emptyState.errorMessageType")
  //                     : file.errors[0].code === "too-many-files"
  //                       ? t("emptyState.errorMessageMaxFiles")
  //                       : "nullllllllll"}
  //               </ListItem>
  //             ))}
  //           </UnorderedList>
  //         </Box>
  //       ),
  //     });
  //   }
  // }, [fileRejections]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onModalClose}
      closeOnOverlayClick={!uploading}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent
        backgroundColor='#c4e8f7'
        rounded='0'
        roundedBottomLeft='20px'
        roundedTopRight='20px'
      >
        <ModalBody marginBottom='10'>
          <ModalCloseButton isDisabled={uploading} />
          <Container
            fontWeight='light'
            maxW='3xl'
            backgroundColor='#c4e8f7'
            centerContent
            color='#777777'
            maxHeight='4xl'
          >
            {reordedList.length === 0 ? (
              <>
                <Image src={upload} boxSize='380' />
                {isDragActive ? (
                  "Drop the videos here ..."
                ) : (
                  <div>
                    <p className='font-bold'>- {t("emptyState.note")} :</p>
                    <p>- {t("emptyState.uploadMsg")}</p>
                    <p>
                      - {t("emptyState.uploadSizeMsg")}{" "}
                      {bytesToSize(videosConstants.MAX_SIZE)}
                    </p>
                  </div>
                )}
                <Center width='50%' {...getRootProps()}>
                  <Button
                    width='100%'
                    rounded='3xl'
                    fontSize='2xl'
                    padding='25px'
                    marginTop='5'
                    bg='white'
                    color='black'
                    _hover={{ bg: "#E6FAFF", shadow: "0px 3px 26px #FFFFFF" }}
                  >
                    {/* <input onChange={() => { console.log("input-01"); }} {...getInputProps()} /> */}
                    {onError ? t("emptyState.uploadAgainBtn") : "Select"}
                  </Button>
                </Center>

                <Box padding='4' fontSize='14px'>
                  {t("emptyState.videosLimit")} {videosConstants.MAX_FILES}
                </Box>
              </>
            ) : (
              <Box padding='4' w='90%'>
                <p className='mb-4 text-2xl'>{t("emptyState.files")}:</p>
                <p className='text-red-500 font-bold mb-2'>{errorMsg}</p>

                <Reorder.Group
                  axis='y'
                  values={reordedList}
                  onReorder={setReorededList}
                  className='flex flex-col gap-4 w-full'
                >
                  {reordedList.map((file, index) => (
                    <DragedItem
                      file={file}
                      index={index}
                      key={file.name + " - " + index}
                      uploading={uploading}
                      totalUplaodedFiles={totalUplaodedFiles}
                    />
                  ))}
                </Reorder.Group>
                {uploading ? (
                  <div>
                    <Progress
                      className='mt-4'
                      hasStripe
                      value={(totalUplaodedFiles / successFiles.length) * 100}
                      isAnimated
                    />
                    <span>
                      {Math.floor(
                        (totalUplaodedFiles / successFiles.length) * 100
                      )}
                      %
                    </span>
                  </div>
                ) : null}
                {reordedList.length < videosConstants.MAX_FILES ? (
                  <>
                    <Center width='100%' display='flex' flexDir='row' gap='4px'>
                      {!uploading ? (
                        <Box {...getRootProps()} w='100%'>
                          <Button
                            width='100%'
                            rounded='3xl'
                            fontSize='lg'
                            padding='10px'
                            marginTop='5'
                            bg='white'
                            color='black'
                            _hover={{
                              bg: "#E6FAFF",
                              shadow: "0px 3px 26px #FFFFFF",
                            }}
                          >
                            {/* <input onChange={() => { console.log("input-02"); }} {...getInputProps()} /> */}
                            {t("emptyState.uploadAgainBtn")}
                          </Button>
                        </Box>
                      ) : null}
                    </Center>
                    <Button
                      width='100%'
                      rounded='3xl'
                      fontSize='lg'
                      padding='10px'
                      marginTop='5'
                      bg='white'
                      color='black'
                      isLoading={uploading}
                      _hover={{ bg: "#E6FAFF", shadow: "0px 3px 26px #FFFFFF" }}
                      onClick={createNewProjectWithParts}
                    >
                      {t("emptyState.uploadBtn")}
                    </Button>
                  </>
                ) : (
                  <Center width='100%' {...getRootProps()}>
                    <Button
                      width='100%'
                      rounded='3xl'
                      fontSize='lg'
                      padding='20px'
                      marginTop='5'
                      bg='white'
                      color='black'
                      isLoading={uploading}
                      _hover={{ bg: "#E6FAFF", shadow: "0px 3px 26px #FFFFFF" }}
                      onClick={createNewProjectWithParts}
                    >
                      {t("emptyState.uploadBtn")}
                    </Button>
                  </Center>
                )}
              </Box>
            )}
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
