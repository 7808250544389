import { Reorder, useDragControls } from "framer-motion";
import { BiCheck } from "react-icons/bi";
import { HiUpload } from "react-icons/hi";
import { MdDragIndicator } from "react-icons/md";
import VideoThumbnail from "./ThumbnailGenerator";

export default function DragedItem({
  file,
  index,
  totalUplaodedFiles,
  uploading,
}) {
  const dragControls = useDragControls();
  return (
    <Reorder.Item
      className='flex justify-between gap-4 bg-[#c4e8f7] p-2 rounded-lg shadow-md relative select-none'
      key={file.name}
      id={file.name}
      value={file}
      dragListener={false}
      dragControls={dragControls}
    >
      <MdDragIndicator
        className='cursor-grab absolute text-xl right-0 '
        width='39'
        height='39'
        onPointerDown={(event) => dragControls.start(event)}
      />
      <VideoThumbnail videoFile={file} />
      <div className='flex items-center px-2 justify-between gap-2 flex-1'>
        <p className='font-bold flex-1'>{file.name}</p>
        <div className=''>
          {uploading ? (
            index + 1 > totalUplaodedFiles ? (
              <HiUpload className='text-2xl text-green-500 animate-pulse' />
            ) : (
              <BiCheck className='text-2xl text-green-500' />
            )
          ) : null}
        </div>
      </div>
    </Reorder.Item>
  );
}
