import { t } from "i18next";
import { useEffect } from "react";

export default function Packages() {
  useEffect(() => {
    document.title = `${t("title.projectName")} | ${t(
      "userDashboard.packages"
    )}`;
  }, []);
  return <div>Packages</div>;
}
