import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Text,
  Center,
  Button,
} from "@chakra-ui/react";
import axios from "axios";
import { t } from "i18next";
import { useState } from "react";
import axoisConfigUtil from "../../../../utils/AxoisConfigUtil";

export default function AnotherVideoModal({
  isOpen,
  onClose,
  project,
  partId,
  setOptions,
  option,
  enabledTypes,
}) {
  const [selectedVidId, setSelectedVidId] = useState(option?.next || "");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const selectVideo = async () => {
    setIsSubmitting(true);
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/options/${option.id}`,
        {
          next: selectedVidId,
          link: null,
          copy: null,
          option_category_id: enabledTypes.find(
            (type) => type.name === "NORMAL"
          ).id,
        },
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then(function (ـ) {
        setOptions((prev) =>
          prev.map((opt) => {
            if (opt.id === option.id) {
              opt.next = selectedVidId;
              opt.name = "NORMAL";
              opt.copy = null;
              opt.link = null;
            }
            return opt;
          })
        );
        onClose();
        setIsSubmitting(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsSubmitting(false);
      });
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={!isSubmitting}
      size='xl'
      isCentered
    >
      <ModalOverlay />
      <ModalContent rounded='0' roundedBottom='50px' roundedTopLeft='50px'>
        <ModalCloseButton
          bg='#00bcee'
          color='white'
          h='25px'
          w='25px'
          rounded='full'
          disabled={isSubmitting}
        />
        <Center padding='10' maxW='xl' display='flex' flexDir='column'>
          <Text color='#00bcee' fontSize='2xl'>
            {t("building.selectNextVid")}:
          </Text>
          <div className='flex flex-row gap-5 mt-2'>
            {project.parts.map((ele, index) => {
              return (
                <div key={ele.id} className='justify-center items-center'>
                  <div
                    className={`h-32 w-20 ${
                      selectedVidId === ele.key
                        ? "border-2 border-primary"
                        : "border border-black"
                    } ${
                      ele.id == partId
                        ? "opacity-50 cursor-no-drop"
                        : "cursor-pointer"
                    } rounded-lg overflow-hidden`}
                    onClick={() => {
                      if (ele.id !== partId) setSelectedVidId(ele.key);
                    }}
                  >
                    <video
                      muted
                      height='100%'
                      width='100%'
                      className='object-cover h-full'
                      autoPlay
                      src={ele.video.path}
                      loop
                    ></video>
                  </div>
                  <p className='text-center text-gray-500 text-sm'>{++index}</p>
                  <p
                    dir='ltr'
                    className='text-center text-gray-500 text-sm w-20 line-clamp-2'
                  >
                    {ele?.name || ""}
                  </p>
                </div>
              );
            })}
          </div>
          <Button
            onClick={selectVideo}
            borderRadius='36px'
            border='1px'
            color='white'
            bg='#00bcee'
            px='16'
            fontWeight='light'
            fontSize='larger'
            py='4'
            marginTop='5'
            _hover={{ backgroundColor: "#00bcee" }}
            isLoading={isSubmitting}
            isDisabled={!selectedVidId}
          >
            {t("building.submit")}
          </Button>
        </Center>
      </ModalContent>
    </Modal>
  );
}
