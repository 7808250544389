// import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import AuthHeader from "./AuthHeader";
import toastifyFile from "../external-libraries/React-toastify/index";
import errorConstants from "../../constants/ErrorConstants";
import axios from "axios";
import axoisConfigUtil from "../../utils/AxoisConfigUtil";

function SendVerifyMail() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get("url")) {
      /**
       * all url = https://activid-api.tawfig.info/api/verify-email/19/7eb60a7da37cc0d0c65d62097925355848a2b507?expires=1678490791&signature=441a700f7e7f880a41ed85cac1b4a58634f9e800cdfd9b4b93867a049dd91ac5
       * searchParams.get("url") = https://activid-api.tawfig.info/api/verify-email/19/7eb60a7da37cc0d0c65d62097925355848a2b507?expires=1678490791
       * searchParams.get("signature") = 441a700f7e7f880a41ed85cac1b4a58634f9e800cdfd9b4b93867a049dd91ac5
       */
      axios
        .get(
          searchParams.get("url") +
            "&signature=" +
            searchParams.get("signature"),
          axoisConfigUtil.getHeadersWithLang()
        )
        .then((res) => {
          toastifyFile.successNotify(res.data.message);
          navigate("/login");
        })
        .catch((error) => {
          toastifyFile.errorNotify(error.response.data.message);
          navigate("/login");
        });
    } else {
      toastifyFile.errorNotify(
        t("general.messages.error.generalError") +
          " : " +
          errorConstants.sendVerifyMail.code
      );
      navigate("/");
    }
  }, []);

  return (
    <>
      <div className='min-h-screen flex flex-col gap-8 items-center bg-[#ebf7fd] custom-bg text-gray-500'>
        <AuthHeader pageType='verifyEmail' />
        <div className='md:w-1/2 w-3/4 mt-10 '>
          <h1 className='text-2xl text-center mb-10'>
            {/* {t("general.messages.info.verificationInProgress")} <CircularProgress size="1rem" /> */}
            {t("general.messages.info.verificationInProgress")}
          </h1>
        </div>
      </div>
    </>
  );
}

export default SendVerifyMail;
