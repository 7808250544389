export const data = {
    ar: `<p>
    يرحّب بكم فريق عمل شركة اكتفيد، ويتقدّم إليكم بالشكر على ثقتكم به،
    ويفيدكم شركة اكتفيد بأنه حرصاً منه ولإدراكه التام بأن المستخدم له
    حقوق، ونحن نحترم خصوصيتك بشكل كبير، ولهذا السبب نبذل قصارى جهدنا
    لتوفير منصة تتوافق مع أعلى معايير الخصوصية للمستخدمين. يُرجى قراءة
    سياسة الخصوصية هذه بعناية، حتى تتمكن من فهم ممارساتنا بالكامل فيما
    يتعلق بالبيانات الشخصية. تعني "البيانات الشخصية" أو "المعلومات
    الشخصية" أي معلومات يمكن استخدامها بمفردها أو جنبًا إلى جنب مع
    بيانات أخرى، لتحديد هوية أي إنسان.
    <br />
    ملاحظة هامة: لا يهدف أي شيء في سياسة الخصوصية هذه إلى تحديد بأي شكل
    من الأشكال حقك القانوني الإلزامي، بما في ذلك حقوقك في التعويض أو
    وسائل الإنفاذ. شركة اكتفيد تسعى للحفاظ على المعلومات الخاصة
    بالمستخدمين وفقاً لآلية سياسة الخصوصية وسرية المعلومات المعمول بها
    في شركة اكتفيد . وعليه فإن شركة اكتفيد توضّح لكم بأن سياسة الخصوصية
    وسرية المعلومات التي سيتم التعامل مع معلوماتكم الخاصة بكم بموجبها
    كما يلي: السياسة.
    <br />
    <p>السياسة.</p>
  </p>
  <p className='font-bold'>
    أولاً: المعلومات التي تحصل عليها شركة اكتفيد وتحتفظ بها في قواعد
    بياناتها
  </p>
  <ul>
    <li>
      المعلومات الشخصية الخاصة بالمستخدم، كالإسم والعمر والبريد
      الالكتروني
    </li>
    <li>
      معلومات الدخول الشخصية الخاصة بالمستخدم، مثل اسم المستخدم وكلمة
      السر والبريد الالكتروني، والسؤال الخاص باسترجاع كلمة السر وإجابته.
    </li>
    <li>
      قد تفرض طبيعة المنصة الالكترونية بعض المعلومات المتعلّقة بالكوكيز
      وذلك لأغراض الكترونية تسهّل التعامل بين الموقع والمستخدم.
    </li>
  </ul>
  <p className='font-bold'>
    ثانياً: هل شركة اكتفيد تشارك هذه المعلومات؟
  </p>
  <ul>
    <li>
      بطبيعة الحال فإن شركة اكتفيد تسعى بالاحتفاظ بهذه المعلومات بما
      يحفظ خصوصية المستخدم، وشركة اكتفيد لا تحتفظ بهذه المعلومات إلا
      بهدف تحسين جودة الموقع الإلكتروني وتيسير التعامل فيما بين شركة
      اكتفيد والمستخدم.
    </li>
    <li>
      كقاعدة عامة فإن جميع هذه المعلومات لا تطلع عليها إلا القائمين على
      شركة اكتفيد، ولن يقوموا بنشرها أو بثها للغير.
    </li>
    <li>
      حيث أن شركة اكتفيد تسعى للحفاظ على سلامة المستخدمين، فإنه – في
      حالة ملاحظة شركة اكتفيد لأي نشاط غير نظامي أو غير شرعي يقوم به
      المستخدم – فإن شركة اكتفيد قد يقوم بإبلاغ الجهات المختصة بعد
      التشاور مع المحامي الخاص بـ شركة اكتفيد.
    </li>
  </ul>
  <p className='font-bold'>
    ثالثاً: ما هو مدى أمان سرية المعلومات الخاصة بالموقع؟{" "}
  </p>
  <ul>
    <li>
      تسعى شركة اكتفيد إلى سرية المعلومات وسياسة الخصوصية الخاصة
      بالمستخدمين و لن تخالف أحكام هذه القواعد والسياسة. ولكن نظراً لعدم
      إمكانية ضمان ذلك 100% عبر وسائل الإنترنت فإن فريق عمل شركة اكتفيد
      ينوّه بما يلي:
      <ul>
        <li>
          تسعى شركة اكتفيد بالحفاظ على جميع المعلومات الخاصة بالمستخدم
          وألا يطلع عليها أحد بما يخالف هذه السياسة المعمول بها في شركة
          اكتفيد.
        </li>
        <li>
          تعمل على حماية هذه المعلومات عن طريق "سيرفرات معيّنة؟ " محمية
          بموجب أنظمة الحماية الإلكترونية "نظامي برمجي ".{" "}
        </li>
        <li>
          نظراً لأن شبكة الانترنت لا يمكن ضمانها 100% لما قد يطرأ من
          اختراق أو فيروسات على أنظمة الحماية الالكترونية وعلى جدران
          الحماية المعمول به في شركة اكتفيد فإن شركة اكتفيد تنصح
          المستخدمين بالحفاظ على معلوماتهم بسرية تامة، وعدم إفشاء أي
          معلومات يراها المستخدم هامة جداً له، وهذا حرصاً على شركة
          اكتفيد في توجيه وإرشاد المستخدمين.
        </li>
      </ul>
    </li>
    <li>
      كقاعدة عامة فإن جميع هذه المعلومات لا تطلع عليها إلا القائمين على
      شركة اكتفيد، ولن يقوموا بنشرها أو بثها للغير.
    </li>
    <li>
      حيث أن شركة اكتفيد تسعى للحفاظ على سلامة المستخدمين، فإنه – في
      حالة ملاحظة شركة اكتفيد لأي نشاط غير نظامي أو غير شرعي يقوم به
      المستخدم – فإن شركة اكتفيد قد يقوم بإبلاغ الجهات المختصة بعد
      التشاور مع المحامي الخاص بـ شركة اكتفيد.
    </li>
  </ul>
  <p className='font-bold'>
    رابعاً: هل هناك طرف ثالث في العلاقة بين شركة اكتفيد والتاجر؟{" "}
  </p>
  <p>
    "تحدد هنا سياسة الخصوصية فيما يتعلّق بالحالات التي يكون هناك طرفاً
    ثالث، كالمسوّقين، والتقنيين، والمستهلك، وأي شخص آخر مرتبط بالعلاقة"
  </p>
  <p className='font-bold'>
    خامساً: قواعد وأحكام استخدام شركة اكتفيد :
  </p>
  <p>
    وضعت سياسة الخصوصية وسرية المعلومات لضمان المصداقية والثقة التي تحرص
    شركة اكتفيد على توفيرها للمستخدمين.
  </p>`,
    en: `<p>
    The Actived team welcomes you and thanks you for your trust in us.
    Actived informs you that, out of its concern and full realization
    that the user has rights, we greatly respect your privacy. For this
    reason, we do our utmost to provide a platform that complies with
    the highest standards of user privacy. Please read this privacy
    policy carefully so that you can fully understand our practices
    regarding personal data. “Personal data” or “personal information”
    means any information that can be used alone or in combination with
    other data to identify any human being. Important note: Nothing in
    this privacy policy is intended to limit your mandatory legal rights
    in any way, including your rights to compensation or enforcement.
    Actived strives to maintain user information in accordance with the
    privacy policy and confidentiality of information mechanism applied
    at Actived. Accordingly, Actived clarifies to you that the privacy
    policy and confidentiality of information under which your personal
    information will be dealt with is as follows: The Policy.
    <br />
    <p>The Policy.</p>
  </p>
  <p className='font-bold'>
    First: The information obtained by Actived and retained in its
    databases
  </p>
  <ul>
    <li>
      The user’s personal information, such as name, age, and email
    </li>
    <li>
      The user’s personal login information, such as username, password,
      email, password recovery question and answer.
    </li>
    <li>
      he nature of the electronic platform may impose some
      cookie-related information for electronic purposes that facilitate
      interaction between the site and the user.
    </li>
  </ul>
  <p className='font-bold'>
    Second: Does Actived share this information?
  </p>
  <ul>
    <li>
      Naturally, Actived strives to retain this information in a way
      that preserves the user’s privacy, and Actived only retains this
      information for the purpose of improving the quality of the
      website and facilitating interaction between Actived and the user.
    </li>
    <li>
      As a general rule, all this information is only accessible to
      those running Actived, and they will not publish or broadcast it
      to others.
    </li>
    <li>
      ince Actived strives to maintain the safety of users, if Actived
      notices any irregular or illegal activity by the user, Actived may
      inform the competent authorities after consulting with Actived’s
      lawyer.
    </li>
  </ul>
  <p className='font-bold'>
    Third: What is the security of the confidentiality of site-specific
    information?{" "}
  </p>
  <ul>
    <li>
      Actived strives for user confidentiality and privacy policy and
      will not violate the provisions of these rules and policies.
      However, due to the impossibility of guaranteeing this 100%
      through internet means, the Actived team notes the following:
      <ul>
        <li>
          Actived strives to preserve all user-specific information and
          not allow anyone to access it in violation of this policy
          applied at Actived.
        </li>
        <li>
          It works to protect this information through “specific
          servers?” protected by electronic protection systems
          “programmatic system”.
        </li>
        <li>
          Due to the fact that the Internet cannot be guaranteed 100%
          due to hacking or viruses on electronic protection systems and
          firewalls applied at Actived, Actived advises users to keep
          their information completely confidential and not disclose any
          information they deem very important to them. This is out of
          Actived’s concern for guiding and advising users.
        </li>
      </ul>
    </li>
    <li>
      As a general rule, all this information is only accessible to
      those running Actived, and they will not publish or broadcast it
      to others.
    </li>
    <li>
      Since Actived strives to maintain the safety of users, if Actived
      notices any irregular or illegal activity by the user, Actived may
      inform the competent authorities after consulting with Actived’s
      lawyer.
    </li>
  </ul>
  <p className='font-bold'>
    Fourth: Is there a third party in the relationship between Actived
    and the merchant?{" "}
  </p>
  <p>
    “The privacy policy is defined here regarding cases where there is a
    third party involved, such as marketers, technicians, consumers, and
    anyone else associated with the relationship.
  </p>
  <p className='font-bold'>
    Fifth: Rules and conditions for using Actived:
  </p>
  <p>
    The privacy policy and confidentiality of information were
    established to ensure credibility and trust that Actived strives to
    provide for users.
  </p>`
}