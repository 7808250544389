import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import toastifyFile from "../external-libraries/React-toastify/index";
import errorConstants from "../../constants/ErrorConstants";
import RoleConstants from "../../constants/RoleConstants";
import AuthHeader from "../auth/AuthHeader";
import axoisConfigUtil from "../../utils/AxoisConfigUtil";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import { useUserContext } from "../../context/userContext";
import { CircularProgress } from "@chakra-ui/react";

function SallaWelcoming({ language, setLanguage }) {
  const [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();
  const { t } = useTranslation();

  const { setUserData } = useUserContext();

  useEffect(() => {
    // document.title = `${t("title.projectName")} | ${t("home.login")}`;

    if (searchParams.get("code")) {

      axios.post(
        `${process.env.REACT_APP_API_URL}/salla/register-store`,
        { code: searchParams.get("code") },
        axoisConfigUtil.getHeadersWithLang()
      )
        .then((response) => {
          if(response.data.update_password){
            localStorage.setItem("update_password", response.data.user.email);
          }
          localStorage.setItem("token", response.data.access_token);
          setUserData(response.data.user);
          if (response.data.user.role === RoleConstants.USER_ROLE) {
            navigate("/dashboard");
          }
          if (response.data.user.role === RoleConstants.ADMIN_ROLE) {
            navigate("/admin-dashboard");
          }
        })
        .catch((error) => {
          toastifyFile.errorNotify(error.response.data.error + " : " + errorConstants.salla.code);
          navigate("/login");
        })
    } else {
      toastifyFile.errorNotify(t("general.messages.error.generalError") + " : " + errorConstants.salla.code);
      navigate("/");
    }
  }, []);




  return (
    <>
      <div className='h-screen flex flex-col gap-8 items-center bg-[#ebf7fd] custom-bg text-gray-500'>
        <AuthHeader pageType='salla' />
        <div className='w-2/5 mt-10 '>

          <h1 className='text-2xl text-center mb-10'>
            اهلا عميل سلة ,  يرجى الانتظار <CircularProgress isIndeterminate color='green.300' />
          </h1>
        </div>
      </div>
    </>
  );
}

export default SallaWelcoming;
