import {
  Box,
  Button,
  Center,
  HStack,
  Image,
  keyframes,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { IoSettingsSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import axoisConfigUtil from "../../../utils/AxoisConfigUtil";
import languageConstants from "../../../constants/LanguageConstants";
import { FaCircle } from "react-icons/fa";
import { useEffect } from "react";
import projectStatusConstants from "../../../constants/ProjectStatusConstants";
import actividIcon from "../../../assets/images/dashboard/platforms/activid/activid-icon.png";
import PlatformsConstants from "../../../constants/PlatformsConstants";
import { useUserContext } from "../../../context/userContext";
import toastifyFile from "../../external-libraries/React-toastify/index";
import errorConstants from "../../../constants/ErrorConstants";

const spin = keyframes`
from {transform: rotate(90deg);}
to {transform: rotate(0deg)}
`;

export default function ProjectItem({ t, project, refetchProjects, language }) {
  const navigate = useNavigate();
  const spinAnimation = `${spin} 1 100ms linear`;

  const [status, setStatus] = useState(projectStatusConstants.READY);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [projectId, setProjectId] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [deleteProjectLoading, setDeleteProjectLoading] = useState(false);

  const { user, setUserData } = useUserContext();

  const HandleDelete = () => {
    setDeleteProjectLoading(true);

    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/projects/${projectId}`,
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then((res) => {
        setDeleteProjectLoading(false);
        onClose();
        refetchProjects();
      })
      .catch((error) => {
        setDeleteProjectLoading(false);
        toastifyFile.errorNotify(
          error.response.data.message + " : " + errorConstants.projectItem.code
        );
      });
  };

  function handelStatus() {
    if (!user.isVerified) {
      setStatus(projectStatusConstants.USER_NOT_VERIFIED);
    } else {
      switch (project.status) {
        case projectStatusConstants.NOT_COMPLETE.key:
          setStatus(projectStatusConstants.NOT_COMPLETE);
          break;
        case projectStatusConstants.READY.key:
          setStatus(projectStatusConstants.READY);
          break;
        case projectStatusConstants.DEPLOYED.key:
          {
            setStatus(projectStatusConstants.DEPLOYED);
          }
          break;
        case projectStatusConstants.SUSPENDED.key:
          {
            setStatus(projectStatusConstants.SUSPENDED);
          }
          break;
        case projectStatusConstants.SUSPENDED_BY_SYSTEM.key:
          {
            setStatus(projectStatusConstants.SUSPENDED_BY_SYSTEM);
          }
          break;
        default: {
          if (project.status.startsWith(projectStatusConstants.STOPPED.key)) {
            setStatus(projectStatusConstants.STOPPED);
          } else {
            // TODO : handel other status , or restarcure/rebuild the logic of code of method ( handelStatus )
          }
        }
      }
    }
  }

  useEffect(() => {
    handelStatus();
  }, [project]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size='lg'>
        <ModalOverlay />
        <ModalContent
          border='0.5px solid #707070'
          borderRadius='87px 0px 87px 87px'
        >
          <ModalCloseButton
            isDisabled={deleteProjectLoading}
            bg='#00bcee'
            color='white'
            h='25px'
            w='25px'
            rounded='full'
          />
          <ModalBody my='50px' color='#00bcee'>
            <Center>
              <Text align='center' fontSize='2xl' px='12' fontWeight='light'>
                {t("general.messages.confirm.deleteProject")}
              </Text>
            </Center>
            <Center mt='6'>
              <Button
                isLoading={deleteProjectLoading}
                onClick={HandleDelete}
                background='#00bcee'
                color='white'
                fontWeight='light'
                variant='outline'
                _hover={{
                  background: "#00bcee",
                }}
                width='50%'
                marginTop='2'
                fontSize='xl'
                rounded='3xl'
                border='0.5px #707070 solid'
              >
                {t("general.delete")}
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box
        key={project.id}
        bg='#323232'
        border='3px solid #00C0F3'
        w={{ base: "210px", xl: "251px" }}
        h={{ base: "350px", xl: "410px" }}
        rounded='40px'
        pos='relative'
      >
        {/* <Box
          h='2rem'
          w='2rem'
          position='absolute'
          top='-0.5rem'
          insetEnd='-0.5rem'
          className='rounded-full z-10 flex items-center justify-center bg-primary hover:bg-[#F47A7A] border-2 border-black'
          onClick={() => {
            onOpen();
            setProjectId(project.id);
          }}
        >
          <GrFormClose className='cursor-pointer text-2xl' />
        </Box> */}
        <Center pos='relative'>
          {isHovered ? (
            <Box
              w='3.5rem'
              h='3.5rem'
              pos='absolute'
              right='35%'
              zIndex='10'
              animation={spinAnimation}
              onMouseOver={() => setIsHovered(true)}
            >
              <IoSettingsSharp
                onClick={() => navigate(project.id)}
                className='cursor-pointer w-14 h-14 stroke-2 rounded-full text-primary text-4xl'
              />
            </Box>
          ) : null}
          <Image
            onMouseOver={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => navigate(project.id)}
            opacity={isHovered ? "0.4" : "1"}
            _hover={{
              cursor: "pointer",
            }}
            src={project.project_img}
            fallbackSrc='https://placehold.co/400x600'
            rounded='40px'
            height={{ base: "270px", xl: "330px" }}
            w='93%'
            pt='1'
          />

          {project.store != null ? (
            <Image
              position={"absolute"}
              src={
                project.store.platForm.platform_identify ===
                PlatformsConstants.salla.platformIdentify
                  ? PlatformsConstants.salla.icon
                  : project.store.platForm.platform_identify ===
                    PlatformsConstants.zid.platformIdentify
                  ? PlatformsConstants.zid.icon
                  : actividIcon
              }
              left='15px'
              top='10px'
              pt='1'
              w='60px'
              opacity={isHovered ? "0.4" : "1"}
              onMouseOver={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => navigate(project.id)}
              _hover={{
                cursor: "pointer",
              }}
            />
          ) : (
            <Image
              position={"absolute"}
              src={actividIcon}
              left='15px'
              top='10px'
              pt='1'
              w='60px'
              opacity={isHovered ? "0.4" : "1"}
              onMouseOver={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => navigate(project.id)}
              _hover={{
                cursor: "pointer",
              }}
            />
          )}
        </Center>
        <Text noOfLines={2} align='center' pt='2' fontSize='md' color='white'>
          {project.name === null
            ? t("projectItem.unnamedProject")
            : project.name}
        </Text>
        <Center>
          <Text
            p={2}
            style={status.color != undefined ? { color: status.color } : null}
          >
            <FaCircle />
          </Text>
          <Text
            fontSize='large'
            style={status.color != undefined ? { color: status.color } : null}
          >
            {language === languageConstants.AR
              ? status.arTitle
              : status.enTitle}
          </Text>
        </Center>
      </Box>
    </>
  );
}
