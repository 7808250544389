import { useEffect, useState } from "react";
import { t } from "i18next";
import EmptyState from "./EmptyState";
import FileUploader from "./FileUploader";
import { Center, Spinner, useDisclosure } from "@chakra-ui/react";
import ProjectsList from "./ProjectsList";
import UpdatePasswordDialog from "./UpdatePasswordDialog";
import { useGetProjects } from "../../../hooks/project";
import Loader from "../../Loader";

function Dashboard({ language }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: updatePasswordDialogIsOpen,
    onOpen: updatePasswordDialogOnOpen,
    onClose: updatePasswordDialogOnClose,
  } = useDisclosure();

  const {
    isOpen: fileUploaderDialogIsOpen,
    onOpen: fileUploaderDialogOnOpen,
    onClose: fileUploaderDialogOnClose,
  } = useDisclosure();

  useEffect(() => {
    document.title = `${t("title.projectName")} | ${t(
      "userDashboard.projects"
    )}`;
    refetch();
  }, []);

  const { data: project, isLoading, isError, refetch } = useGetProjects();

  if (isLoading) {
    return (
      <Center height='300px' w='full' display='flex' flexDir='column'>
        <Loader isLoading={true} />
      </Center>
    );
  }

  return (
    <>
      <FileUploader isOpen={fileUploaderDialogIsOpen} onClose={fileUploaderDialogOnClose} />
      <UpdatePasswordDialog
        language={language}
        isOpen={updatePasswordDialogIsOpen}
        onClose={updatePasswordDialogOnClose}
      />
      {project.length === 0 ? (
        <EmptyState fileUploaderDialogOnOpen={fileUploaderDialogOnOpen} t={t} />
      ) : (
        <>
          <ProjectsList
            projects={project}
            refetchProjects={refetch}
            fileUploaderDialogOnOpen={fileUploaderDialogOnOpen}
            updatePasswordDialogOnOpen={updatePasswordDialogOnOpen}
            t={t}
            language={language}
          />
        </>
      )}
    </>
  );
}

export default Dashboard;
