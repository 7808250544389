import i18next from "i18next";
import i18n from '../i18n';


function setBrowserLanguage() {
    i18next.changeLanguage(navigator.language);
    document.body.dir = i18n.dir();
}

const i18nextUtils = {
    setBrowserLanguage,
};

export default i18nextUtils;
