// AxiosNavigation.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import toastifyFile from "../components/external-libraries/React-toastify/index";
import errorConstants from '../constants/ErrorConstants';
import { t } from 'i18next';


export function useAxiosInterceptors() {

    let navigate = useNavigate();

    // Response interceptor
    useEffect(() => {
        const intercetpor = axios.interceptors.response.use(
            (response) => response,
            (error) => {
                switch (error?.response?.status) {
                    case 500:
                        // TODO : Think about what should be here
                        break;
                    case 401:
                        localStorage.removeItem("token");
                        localStorage.removeItem("user");
                        toastifyFile.errorNotify(t("general.messages.error.loginAgain") + " : " + errorConstants.axiosInterceptors.code);
                        navigate('/');
                        break;
                    default:
                }
                return Promise.reject(error);
            }
        );
        return () => {
            axios.interceptors.response.eject(intercetpor);
        };
    }, []);


    //  Request interceptor
    // useEffect(() => {
    //     const intercetpor = axios.interceptors.request.use(
    //         (config) => {
    //             // Modify the request config here (e.g., add headers, authentication tokens)
    //             const accessToken = JSON.parse(localStorage.getItem("token"));

    //             // ** If token is present add it to request's Authorization Header
    //             if (accessToken) {
    //                 if (config.headers) config.headers.token = accessToken;
    //             }
    //             return config;
    //         },
    //         (error) => {
    //             // Handle request errors here
    //             return Promise.reject(error);
    //         }
    //     );
    // }, []);

}

export default function AxiosInterceptors() {
    useAxiosInterceptors();
    return <></>;
}