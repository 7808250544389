import axios from "axios";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import errorConstants from "../../constants/ErrorConstants";

import toastifyFile from "../../components/external-libraries/React-toastify/index";
import { useNavigate } from "react-router";
import AuthHeader from "./AuthHeader";
import axoisConfigUtil from "../../utils/AxoisConfigUtil";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/input";
import { BsCheck2Circle } from "react-icons/bs";
import { BiError } from "react-icons/bi";
import { Stack } from "@chakra-ui/layout";
import languageConstants from "../../constants/LanguageConstants";

function ResetPassword({ language, setLanguage }) {
  // const [email, setEmail] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `${t("title.projectName")} | ${t(
      "resetPassword.resetPassword"
    )}`;
  }, []);

  let navigate = useNavigate();

  function backTologin() {
    navigate(`/login`);
  }

  function submitResetPassword() {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/forget-password`,
        {
          email: formik.values.email,
          url: process.env.REACT_APP_RESET_PASSWORD_URL,
        },
        axoisConfigUtil.getHeadersWithLang()
      )
      .then(function (response) {
        toastifyFile.successNotify(response.data.message);
        backTologin();
      })
      .catch(function (error) {
        document.getElementById("emailId").value = "";
        if (!error.response) {
          // network error
          toastifyFile.errorNotify(
            t("general.messages.error.connectionRefused") +
            " : " +
            errorConstants.resetPassword.code
          );
        }

        if (error.response.status === 401 || error.response.status === 400) {
          formik.setErrors({ email: error.response.data.message });
          // toastifyFile.errorNotify(error.response.data.message);
        } else {
          toastifyFile.errorNotify(
            t("general.messages.error.generalError") +
            " : " +
            errorConstants.resetPassword.code
          );
        }
      });
  }

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("formik.email"))
        .required(t("formik.required")),
    }),
    onSubmit: (values) => {
      submitResetPassword();
    },
  });

  return (
    <>
      <div className='min-h-screen flex flex-col gap-8 items-center bg-[#ebf7fd] custom-bg text-gray-500'>
        <AuthHeader
          pageType='reset'
          language={language}
          setLanguage={setLanguage}
        />
        <div className='md:w-1/2 w-3/4 mt-10'>
          <h4 className='text-2xl text-center mb-10'>
            {t("resetPassword.resetPassword")}
          </h4>
          <form onSubmit={formik.handleSubmit}>
            <div className='flex flex-col gap-2 p-7 bg-white rounded-tr-2xl rounded-bl-2xl'>
              <Stack spacing={4}>
                <label className='font-light text-xl' htmlFor='email'>
                  {t("signUp.email")}
                </label>
                <InputGroup>
                  <Input
                    id='emailId'
                    name='email'
                    type='mail'
                    required
                    isInvalid={formik.errors.email && formik.touched.email}
                    errorBorderColor='red.300'
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                  />
                  {language === languageConstants.AR ? (
                    <InputLeftElement
                      children={
                        !formik.errors.email && formik.touched.email ? (
                          <BsCheck2Circle className='text-green-700' />
                        ) : formik.errors.email && formik.touched.email ? (
                          <BiError className='text-red-400' />
                        ) : (
                          <></>
                        )
                      }
                    />
                  ) : (
                    <InputRightElement
                      children={
                        !formik.errors.email && formik.touched.email ? (
                          <BsCheck2Circle className='text-green-700' />
                        ) : formik.errors.email && formik.touched.email ? (
                          <BiError className='text-red-400' />
                        ) : (
                          <></>
                        )
                      }
                    />
                  )}
                </InputGroup>
                {formik.errors.email && formik.touched.email && (
                  <div className='text-red-400'>* {formik.errors.email}</div>
                )}
              </Stack>
            </div>
            <button
              type='submit'
              className='w-1/3 hover:w-[37%] transition-all mx-auto mt-8 block py-2 text-lg font-light rounded-full text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary'
            >
              {t("resetPassword.reset")}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
