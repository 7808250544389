import { t } from "i18next";
import { useEffect } from "react";
import AuthHeader from "./AuthHeader";
import { data } from "../../data/termsOfUse";

function TermsOfUse({ language, setLanguage }) {
  useEffect(() => {
    document.title = `${t("title.projectName")} | ${t(
      "signUp.termsAndConditions"
    )}`;
  }, []);

  return (
    <div className='h-full flex flex-col gap-8 items-center bg-[#ebf7fd] custom-bg-2 text-gray-500 terms'>
      <AuthHeader language={language} setLanguage={setLanguage} pageType={"reset"} />
      <div className='w-5/6 my-auto'>
        <div className='flex flex-col mb-10 justify-center items-center gap-2 bg-white rounded-tr-[193px] rounded-bl-[193px] pt-10 pb-24 md:px-20 px-10'>
          <p className='mb-4 text-3xl w-full md:text-center'>
            {t("signUp.termsAndConditions")}
          </p>
          <div dangerouslySetInnerHTML={{ __html: data[language] }}></div>
        </div>
      </div>
    </div>
  );
}

export default TermsOfUse;
