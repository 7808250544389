import { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import Option from "./Option";
import PartName from "./PartName";
import PartsList from "./PartsList";
import Preview from "./Preview";
import axios from "axios";
import axoisConfigUtil from "../../../utils/AxoisConfigUtil";
import { Box, Button, Text } from "@chakra-ui/react";
import projectConstants from "../../../constants/ProjectConstants";

export default function Part({
  project,
  part,
  setPart,
  t,
  enabledTypes,
  removePartById,
  setProject,
  activePart,
  setPreviewOptions,
}) {
  const [options, setOptions] = useState([]);
  const [addOptionLoading, setAddOptionLoading] = useState(false);

  useEffect(() => {
    setOptions(part?.options || []);
    setPreviewOptions(part?.options || []);
  }, [part]);

  const handleNewOption = async () => {
    setAddOptionLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/options`,
        {
          text: t("building.buttonText"),
          part_id: activePart,
          option_category_id: enabledTypes.find((type) => type.name === "COPY")
            .id,
          copy: t("building.copyableText"),
        },
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then(function (response) {
        setAddOptionLoading(false);
        setOptions((prev) => [...prev, response.data.option]);
        setPreviewOptions((prev) => [...prev, response.data.option]);
        setProject((prev) => {
          const parts = prev.parts.map((p) => {
            if (p.id === activePart) {
              if (p.hasOwnProperty("options")) {
                p.options = [...p.options, response.data.option];
              } else {
                p.options = [response.data.option];
              }
            }
            return p;
          });
          return {
            parts,
            ...prev,
          };
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <Box w='auto' m='2'>
        {/* PartName : Temporary hidden due , has bug ( dubliction for all parts) & there is no real benefit of it  */}
        {/* <PartName part={part} t={t} setProject={setProject} /> */}
        <div className='flex items-center'>
          <p className='w-32 text-lg my-5'>{t("building.buttons")}</p>
          {options.length < projectConstants.MAX_OPTIONS_PER_PROJECT ? (
            <Button
              backgroundColor='#00bcee'
              background='#00bcee'
              variant='#00bcee'
              color='white'
              _disabled={{
                bg: "#00bcee",
                _hover: {
                  bg: "#00bcee",
                  opacity: "0.4",
                  cursor: "not-allowed",
                },
              }}
              rounded={"full"}
              fontWeight={"light"}
              className='flex items-center gap-2'
              onClick={handleNewOption}
              isLoading={addOptionLoading}
              loadingText={t("building.addButton")}
            >
              <IoIosAddCircle className='text-2xl' />
              <Text>{t("building.addButton")}</Text>
            </Button>
          ) : (
            <Button
              title={t("part.disabledAddButton")}
              backgroundColor='#00bcee'
              background='#00bcee'
              variant='#00bcee'
              color='white'
              opacity='0.4'
              _disabled={{
                bg: "#00bcee",
                _hover: {
                  bg: "#00bcee",
                  opacity: "0.4",
                  cursor: "not-allowed",
                },
              }}
              rounded={"full"}
              fontWeight={"light"}
              className='flex items-center gap-2'
              isDisabled={true}
            >
              <IoIosAddCircle className='text-2xl' />
              <Text>{t("building.addButton")}</Text>
            </Button>
          )}
        </div>
        {options.length
          ? options.map((opt) => (
              <Option
                key={opt.id}
                t={t}
                project={project}
                option={opt}
                options={options}
                enabledTypes={enabledTypes}
                activePart={activePart}
                setOptions={setOptions}
                setProject={setProject}
                setPreviewOptions={setPreviewOptions}
              />
            ))
          : null}
      </Box>
    </>
  );
}
