import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Text,
  Center,
  Button,
  Input,
  Checkbox,
} from "@chakra-ui/react";
import axios from "axios";
import { t } from "i18next";
import { useState } from "react";
import { BsCheckLg } from "react-icons/bs";
import axoisConfigUtil from "../../../../utils/AxoisConfigUtil";

export default function ExternalLinkModal({
  isOpen,
  onClose,
  setOptions,
  option,
  enabledTypes,
}) {
  const [url, setUrl] = useState(option.link || "");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const onSubmit = () => {
    setIsSubmitting(true);
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/options/${option.id}`,
        {
          link: url,
          copy: null,
          next: null,
          option_category_id: enabledTypes.find(
            (type) => type.name === "EXTERNAL LINK"
          ).id,
        },
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then(function (ـ) {
        setOptions((prev) =>
          prev.map((opt) => {
            if (opt.id === option.id) {
              opt.link = url;
              opt.name = "EXTERNAL LINK";
              opt.copy = null;
              opt.next = null;
            }
            return opt;
          })
        );
        onClose();
        setErrorMsg(null);
        setIsSubmitting(false);
      })
      .catch(function (error) {
        setErrorMsg(error.response.data.message);
        setIsSubmitting(false);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setErrorMsg(null);
        setUrl(option.link);
      }}
      size='xl'
      isCentered
      closeOnOverlayClick={!isSubmitting}
    >
      <ModalOverlay />
      <ModalContent rounded='0' roundedBottom='50px' roundedTopLeft='50px'>
        <ModalCloseButton
          bg='#00bcee'
          color='white'
          h='25px'
          w='25px'
          rounded='full'
          disabled={isSubmitting}
        />
        <Center padding='10' maxW='xl' display='flex' flexDir='column'>
          <Text
            color='#00bcee'
            fontSize='3xl'
            fontWeight='light'
            alignSelf='start'
          >
            {t("building.link")}
          </Text>
          <Input
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            rounded='50px'
            borderColor='blackAlpha.500'
            my='4'
            h='45px'
          />
          <Text color='red.500' textAlign='start' w='full'>
            {errorMsg}
          </Text>
          <Button
            isDisabled={!url}
            onClick={onSubmit}
            borderRadius='36px'
            borderColor='blackAlpha.500'
            borderWidth='thin'
            color='white'
            bg='#00bcee'
            px='20'
            fontWeight='light'
            fontSize='3xl'
            py='22px'
            marginTop='5'
            isLoading={isSubmitting}
            _hover={{ backgroundColor: "#00bcee" }}
          >
            {t("building.submit")}
          </Button>
        </Center>
      </ModalContent>
    </Modal>
  );
}

function CustomIcon(props) {
  return <>{props.isChecked ? <BsCheckLg color='black' /> : null}</>;
}
