import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Container,
  Stack,
  InputGroup,
  Input,
  InputLeftElement,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BsCheck2Circle } from "react-icons/bs";
import { BiError } from "react-icons/bi";
import languageConstants from "../../../constants/LanguageConstants";
import toastifyFile from "../../external-libraries/React-toastify/index";
import axios from "axios";
import axoisConfigUtil from "../../../utils/AxoisConfigUtil";


export default function UpdatePasswordDialog({ language, isOpen, onClose }) {

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required(t("formik.required")).min(8, t("formik.minPassword")),
    }),
    onSubmit: (values) => {
      login();
    },
  });

  function onModalClose() {
    onClose();
  }

  function login() {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/user/update-password`,
        {
          password: formik.values.password,
          password_confirmation: formik.values.password,
        },
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then(function (response) {
        toastifyFile.successNotify(t("updatePasswordDialog.messages.success.updatedSuccessfully"));
        localStorage.removeItem("update_password")
        onModalClose();
      })
      .catch(function (error) {
        // TODO : ask abduziz what errors ll get from backend to handle  it.
        console.log(error);
      });
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onModalClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent
        backgroundColor='#c4e8f7'
        rounded='0'
        roundedBottomLeft='20px'
        roundedTopRight='20px'
      >
        <ModalBody>
          <ModalCloseButton />
          <Container
            fontWeight='light'
            maxW='3xl'
            backgroundColor='#c4e8f7'
            centerContent
            color='#777777'
            maxHeight='4xl'>

            <div className="w-full m-5">
              <div className='w-full my-auto'>
                <form onSubmit={formik.handleSubmit}>
                  <div className='flex flex-col gap-2 p-7 bg-white rounded-tr-2xl rounded-bl-2xl'>
                    <Stack>
                      <Text px='40px' fontSize='3xl' className="text-center">
                        {t("updatePasswordDialog.updateMessageAction")}
                      </Text>
                      <label className='font-light text-xl'>
                        {t("signUp.email")}
                      </label>
                      <InputGroup>
                        <Input
                          isDisabled
                          value={localStorage.getItem("update_password")}
                        />
                      </InputGroup>
                      <label className='font-light text-xl' htmlFor='password'>
                        {t("signUp.password")}
                      </label>
                      <InputGroup>
                        <Input
                          id='passwordId'
                          name='password'
                          type='password'
                          required
                          isInvalid={
                            formik.errors.password && formik.touched.password
                          }
                          errorBorderColor='red.300'
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                        />
                        {language === languageConstants.AR ? (
                          <InputLeftElement
                            children={
                              !formik.errors.password && formik.touched.password ? (
                                <BsCheck2Circle className='text-green-700' />
                              ) : formik.errors.password &&
                                formik.touched.password ? (
                                <BiError className='text-red-400' />
                              ) : (
                                <></>
                              )
                            }
                          />
                        ) : (
                          <InputRightElement
                            children={
                              !formik.errors.password && formik.touched.password ? (
                                <BsCheck2Circle className='text-green-700' />
                              ) : formik.errors.password &&
                                formik.touched.password ? (
                                <BiError className='text-red-400' />
                              ) : (
                                <></>
                              )
                            }
                          />
                        )}
                      </InputGroup>
                      {formik.errors.password && formik.touched.password && (
                        <div className='text-red-400'>* {formik.errors.password}</div>
                      )}
                    </Stack>
                  </div>
                  <button type='submit' className='w-1/3 hover:w-[37%] transition-all mx-auto mt-8 block py-2 text-lg font-light rounded-full text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary'>
                    {t("updatePasswordDialog.update")}
                  </button>
                </form>
              </div>
            </div>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
