import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import axoisConfigUtil from "../../../utils/AxoisConfigUtil";
import { useEffect, useState } from "react";
import { IoChevronDown, IoChevronUp, IoTrashOutline } from "react-icons/io5";
import AnotherVideoModal from "./optionsModals/AnotherVideoModal";
import CopyModal from "./optionsModals/CopyModal";
import ExternalLinkModal from "./optionsModals/ExternalLinkModal";

export default function Option({
  t,
  project,
  option,
  options,
  setOptions,
  enabledTypes,
  activePart,
  setProject,
  setPreviewOptions,
}) {
  const [optionType, setOptionType] = useState(null);
  const [value, setValue] = useState(option.text || "");
  const [deleteOptionLoading, setDeleteOptionLoading] = useState(false);

  const anotherVideoDisclosure = useDisclosure();
  const {
    onOpen: onAVOpen,
    onClose: onAVClose,
    isOpen: isAVOpen,
  } = anotherVideoDisclosure;
  const copyDisclosure = useDisclosure();
  const {
    onOpen: onCTOpen,
    onClose: onCTClose,
    isOpen: isCTOpen,
  } = copyDisclosure;
  const externalDisclosure = useDisclosure();
  const {
    onOpen: onELOpen,
    onClose: onELClose,
    isOpen: isELOpen,
  } = externalDisclosure;

  const handleDelete = () => {
    setDeleteOptionLoading(true);

    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/options/${option.id}`,
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then((res) => {
        setDeleteOptionLoading(false);
        setOptions((prev) => prev.filter((o) => o.id !== option.id));
        setPreviewOptions((prev) => prev.filter((o) => o.id !== option.id));
        setProject((prev) => {
          const parts = prev.parts.map((p) => {
            if (p.id === activePart) {
              p.options = options.filter((opt) => opt.id !== option.id);
            }
            return p;
          });
          return {
            parts,
            ...prev,
          };
        });
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    let timeout;
    if (value !== option.text) {
      setPreviewOptions(
        options.map((opt) => {
          if (opt.id === option.id) {
            opt.text = value;
          }
          return opt;
        })
      );
      timeout = setTimeout(() => {
        axios
          .put(
            `${process.env.REACT_APP_API_URL}/options/${option.id}`,
            {
              text: value,
            },
            axoisConfigUtil.getHeadersWithLangAndToken()
          )
          .then(function (_) {
            setOptions(
              options.map((opt) => {
                if (opt.id === option.id) {
                  opt.text = value;
                }
                return opt;
              })
            );
            setProject((prev) => {
              const parts = prev.parts.map((p) => {
                if (p.id === activePart) {
                  p.options = options.map((opt) => {
                    if (opt.id === option.id) {
                      opt.text = value;
                    }
                    return opt;
                  });
                }
                return p;
              });
              return {
                parts,
                ...prev,
              };
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      }, 2000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [value]);

  return (
    <>
      <AnotherVideoModal
        isOpen={isAVOpen}
        onClose={onAVClose}
        project={project}
        partId={activePart}
        setOptions={setOptions}
        option={option}
        enabledTypes={enabledTypes}
      />
      <CopyModal
        isOpen={isCTOpen}
        onClose={onCTClose}
        setOptions={setOptions}
        option={option}
        enabledTypes={enabledTypes}
      />
      <ExternalLinkModal
        isOpen={isELOpen}
        onClose={onELClose}
        setOptions={setOptions}
        option={option}
        enabledTypes={enabledTypes}
      />

      <Flex gap='0.5rem' mb='1.25rem' me='1.5rem' className='w-full'>
        <input
          type='text'
          name='name'
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className='border border-gray-300 rounded-xl w-2/5 py-1 px-2 placeholder:text-base flex-1'
          placeholder={t("building.buttonText")}
        />
        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton
                rounded='15px'
                as={Button}
                bg='#323232'
                color='#00bcee'
                _hover={{
                  color: "white",
                  bg: "#323232",
                }}
                _expanded={{
                  color: "white",
                  bg: "#323232",
                }}
                w='35%'
                fontWeight='light'
                rightIcon={isOpen ? <IoChevronUp /> : <IoChevronDown />}
              >
                {option.name === "NORMAL"
                  ? t("building.anotherVideo")
                  : option.name === "EXTERNAL LINK"
                  ? t("building.externalLink")
                  : option.name === "COPY"
                  ? t("building.copyableText")
                  : t("building.buttonType")}
              </MenuButton>
              <MenuList rounded='15px'>
                {enabledTypes.some((t) => t.name == "NORMAL") ? (
                  <MenuItem onClick={() => onAVOpen()}>
                    {t("building.anotherVideo")}
                  </MenuItem>
                ) : null}
                {enabledTypes.some((t) => t.name == "EXTERNAL LINK") ? (
                  <MenuItem onClick={() => onELOpen()}>
                    {t("building.externalLink")}
                  </MenuItem>
                ) : null}
                {enabledTypes.some((t) => t.name == "COPY") ? (
                  <MenuItem onClick={() => onCTOpen()}>
                    {t("building.copyableText")}
                  </MenuItem>
                ) : null}
              </MenuList>
            </>
          )}
        </Menu>
        <Button
          background='#FFFF'
          variant='#FFFF'
          rounded={"full"}
          isLoading={deleteOptionLoading}
          onClick={() => handleDelete()}
        >
          <IoTrashOutline className='text-primary text-3xl' />
        </Button>
      </Flex>
    </>
  );
}
