import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Text,
  Center,
  Button
} from "@chakra-ui/react";
import axios from "axios";
import { t } from "i18next";
import { useState } from "react";
import axoisConfigUtil from "../../../../../utils/AxoisConfigUtil";


export default function DeletePartDialog({ language, isOpen, onClose, partSelectedToDeleteId, setActivePart, removePartById }) {

  const [isSubmitting, setIsSubmitting] = useState(false);


  function onModalClose() {
    onClose();
  }


  const HandleDeletePart = () => {

    setIsSubmitting(true);

    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/parts/${partSelectedToDeleteId}`,
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then((res) => {

        removePartById(partSelectedToDeleteId, res.data.project.status, res.data.project.widget_design.gifUrl)
        setActivePart(null);
        setIsSubmitting(false);
        onModalClose();

      })
      .catch((error) => console.log(error));
  };



  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={!isSubmitting}
      size='lg'>
      <ModalOverlay />
      <ModalContent
        border='0.5px solid #707070'
        borderRadius='87px 0px 87px 87px'
      >
        <ModalCloseButton
          bg='#00bcee'
          color='white'
          h='25px'
          w='25px'
          rounded='full'
          disabled={isSubmitting}
        />
        <ModalBody my='50px' color='#00bcee'>
          <Center>
            <Text align='center' fontSize='2xl' px='12' fontWeight='light'>
              {t("general.messages.confirm.deletePart")}
            </Text>
          </Center>
          <Center mt='6'>
            <Button
              onClick={HandleDeletePart}
              background='#00bcee'
              color='white'
              fontWeight='light'
              variant='outline'
              _hover={{
                background: "#00bcee",
              }}
              width='50%'
              marginTop='2'
              fontSize='xl'
              rounded='3xl'
              border='0.5px #707070 solid'
              isLoading={isSubmitting}
            >
              {t("general.delete")}
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
