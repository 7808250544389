import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ListItem,
  UnorderedList,
  Box,
  Image,
  Container,
  Button,
  Center,
} from "@chakra-ui/react";
import axios from "axios";
import axoisConfigUtil from "../../../utils/AxoisConfigUtil";
import upload from "../../../assets/images/dashboard/loading/before-uploading.png";
import uploadingGif from "../../../assets/images/dashboard/loading/while_uploading.gif";
import { t } from "i18next";
import videosConstants from "../../../constants/VideosConstants";
import bytesToSize from "../../../helpers/bytesToSize";

export default function FileUploaderForPart({
  projectId,
  isOpen,
  onClose,
  appNewPart,
  setProject,
}) {
  const [uploading, setUploading] = useState(false);
  const [onError, setOnError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successFiles, setSuccessFiles] = useState([]);

  function createPart(file) {
    let partFormData = new FormData();
    partFormData.append("project_id", projectId);
    partFormData.append("file", file);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/parts`,
        partFormData,
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then((res) => {
        appNewPart(
          res.data.part,
          res.data.project.status,
          res.data.project.widget_design.gifUrl
        );
        setTimeout(() => {
          onModalClose();
        }, 500);
        axios
          .put(
            `${process.env.REACT_APP_API_URL}/parts/${res.data.part.id}`,
            {
              name: file.name,
            },
            axoisConfigUtil.getHeadersWithLangAndToken()
          )
          .then(function (response) {
            setProject((prev) => {
              const parts = prev.parts.map((p) => {
                if (p.id === res.data.part.id) {
                  p.name = file.name;
                }
                return p;
              });
              return {
                parts,
                ...prev,
              };
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch((error) => {
        setErrorMsg(error?.response?.data?.message || null);
        setOnError(true);
        setUploading(false);
      });
  }

  function onModalClose() {
    setOnError(false);
    setErrorMsg(null);
    setUploading(false);
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    setSuccessFiles([]);
    onClose();
    // inputRef.current.value = "";
  }

  const onDrop = useCallback((accepted, rejections) => {
    if (rejections.length === 0 && accepted.length) {
      setUploading(true);
      setOnError(false);
      setErrorMsg(null);
      createPart(accepted[0]);
      setSuccessFiles(accepted);
    } else {
      setUploading(false);
      if (rejections.length) {
        setOnError(true);
        fileRejections.length = 0;
        fileRejections.splice(0, fileRejections.length);
        setSuccessFiles([]);
        inputRef.current.value = "";
        for (const rejection of rejections) {
          for (const errorR of rejection.errors) {
            if (errorR.code === "file-too-large") {
              setErrorMsg(t("emptyState.errorMessageMaxSize"));
            }
            if (errorR.code === "file-invalid-type") {
              setErrorMsg(t("emptyState.errorMessageType"));
            }
          }
        }
      }
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    fileRejections,
    inputRef,
  } = useDropzone({
    onDrop,
    accept: videosConstants.ALLOWED_VIDEO_TYPES,
    maxFiles: videosConstants.MAX_FILES,
    multiple: false,
    maxSize: videosConstants.MAX_SIZE,
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onModalClose}
      size='xl'
      closeOnOverlayClick={!uploading}
    >
      <ModalOverlay />
      <ModalContent
        backgroundColor='#c4e8f7'
        rounded='0'
        roundedBottomLeft='20px'
        roundedTopRight='20px'
      >
        <ModalBody marginBottom='10'>
          <ModalCloseButton isDisabled={uploading} />
          <Container
            fontWeight='light'
            maxW='3xl'
            backgroundColor='#c4e8f7'
            centerContent
            color='#777777'
            maxHeight='4xl'
          >
            {uploading && !onError ? (
              <Image src={uploadingGif} boxSize='380' />
            ) : (
              <Image src={upload} boxSize='380' />
            )}
            {!onError ? (
              <Box maxW='md'>
                {successFiles.length ? (
                  <UnorderedList>
                    `$
                    {successFiles.map((file) => (
                      <ListItem key={file.name}>{file.name}</ListItem>
                    ))}
                    `
                  </UnorderedList>
                ) : isDragActive ? (
                  `Drop the videos here ... ${videosConstants.MAX_SIZE}`
                ) : (
                  <div>
                    <p className='font-bold'>- {t("emptyState.note")} :</p>
                    <p>- {t("emptyState.uploadMsg")}</p>
                    <p>
                      - {t("emptyState.uploadSizeMsg")}{" "}
                      {bytesToSize(videosConstants.MAX_SIZE)}
                    </p>
                  </div>
                )}
              </Box>
            ) : null}
            <Center width='50%' {...getRootProps()}>
              {!uploading ? (
                <Button
                  width='100%'
                  rounded='3xl'
                  fontSize={onError ? "2xl" : "3xl"}
                  padding='25px'
                  marginTop='5'
                  bg='white'
                  color='black'
                  _hover={{ bg: "#E6FAFF", shadow: "0px 3px 26px #FFFFFF" }}
                >
                  <input {...getInputProps()} />
                  {onError
                    ? t("emptyState.uploadAgainBtn")
                    : t("emptyState.uploadBtn")}
                </Button>
              ) : null}
            </Center>
            {onError ? (
              <Box color='red.500' fontSize='lg' padding='4'>
                {errorMsg ? errorMsg : t("emptyState.errorMessageGeneral")}
              </Box>
            ) : null}
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
