import { useTranslation } from "react-i18next";
import { SlScreenDesktop } from "react-icons/sl";

function NotSupportedPhoneScreen() {
  const { t } = useTranslation();

  function navigateTolink(link) {
    window.location.replace(link);
  }

  return (
    <div className='h-screen flex flex-col gap-5 justify-center items-center bg-secondary'>
      <div>
        <img src='images/logo2.png' alt='logo' height={50} width={180} />
      </div>
      <div className='flex flex-col gap-5 justify-center items-center bg-white w-3/4 h-[457px] rounded-tl-[55px] '>
        <div>
          <SlScreenDesktop size={70} />
        </div>
        <div className='text-primary text-center'>
          <span className='text-2xl block'>
            {t("notSupportedPhoneScreen.title")},
          </span>
          {t("notSupportedPhoneScreen.message")}.
        </div>
        <button
          className='w-1/3 hover:w-[37%] transition-all mx-auto mt-8 block py-2 text-lg font-light rounded-full text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary'
          onClick={() => {
            navigateTolink(process.env.REACT_APP_LANDING_PAGE);
          }}
        >
          {t("notSupportedPhoneScreen.back")}
        </button>
      </div>
    </div>
  );
}

export default NotSupportedPhoneScreen;
