import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { FaUserAlt } from "react-icons/fa";
import toastifyFile from "../../../external-libraries/React-toastify/index";
import { useState } from "react";
import CustomReactSwitch from "../../../external-libraries/react-switch/CustomReactSwitch";
import { Box } from "@chakra-ui/react";
import logo from "../../../../assets/images/logo2.png";

function UserNavBar({ language, setLanguage }) {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  function logOut() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    toastifyFile.successNotify(t("general.messages.success.SignedOut"));
    navigate("/");
  }

  return (
    <Box
      className='h-20 flex flex-row justify-between bg-secondary'
      me='2.5rem'
    >
      <div className='m-6'>
        <img src={logo} alt='logo' height={50} width={180} />
      </div>
      <div className='flex flex-col justify-center relative'>
        <div className='flex items-center gap-2'>
          <div className='m-2'>
            <CustomReactSwitch
              language={language}
              setLanguage={setLanguage}
            ></CustomReactSwitch>
          </div>
          <div
            className='flex items-center gap-2 cursor-pointer'
            onClick={() => setIsActive((prev) => !prev)}
          >
            <FaUserAlt className='bg-primary rounded-full p-1 h-8 w-8 text-secondary' />
            <p className='text-white hover:text-primary font-light'>
              {JSON.parse(localStorage.getItem("user")).name}
            </p>

            {isActive ? (
              <FiChevronUp className='text-primary text-lg' />
            ) : (
              <FiChevronDown className='text-primary text-lg' />
            )}
          </div>
        </div>
        {isActive ? (
          <div className='my-2 absolute w-full top-14'>
            <button
              onClick={() => logOut()}
              className='border w-full border-transparent text-sm font-medium rounded-md text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2'
            >
              log out
            </button>
          </div>
        ) : null}
      </div>
    </Box>
  );
}

export default UserNavBar;
