import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  CircularProgress,
  HStack,
  Image,
  Input,
  Skeleton,
  SkeletonCircle,
  Text,
} from "@chakra-ui/react";
import previewWidgetDesign from "../../../assets/images/dashboard/previewWidgetDesign.png";
import { useState } from "react";
import Switch from "react-switch";
import { useEffect } from "react";
import axios from "axios";
import axoisConfigUtil from "../../../utils/AxoisConfigUtil";
import { AiOutlineCloudUpload } from "react-icons/ai";


import modalDesignIcon from "../../../assets/images/dashboard/projectDesign/modalDesignIcon.svg";
import gifLoadingIcon from "../../../assets/images/dashboard/projectDesign/gifLoadingIcon.png";
import languageConstants from "../../../constants/LanguageConstants";

function ProjectDesign({
  t,
  language,
  widgetDesign,
  updateWidgetDesignStyle,
  updateCustomGif,
  projectRemoveCustomGif,
}) {
  const [isLeft, setIsLeft] = useState(false);
  const [isRectangular, setIsRectangular] = useState(true);
  const [widgetText, setWidgetText] = useState("");
  const [isColorChecked, setIsColorChecked] = useState(false);
  const [color, setColor] = useState("");
  const [gif, setGif] = useState("");
  const [isCustomGifChecked, setIsCustomGifChecked] = useState(false);
  const [isUploadingCustomGif, setIsUploadingCustomGif] = useState(false);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    setIsLeft(widgetDesign.style.widgetPosition === "LEFT" ? true : false);
    setIsRectangular(
      widgetDesign.style.widgetShape === "CIRCLE" ? false : true
    );
    setIsColorChecked(widgetDesign.style.hasBorder);
    setColor(
      widgetDesign.style.hasBorder ? widgetDesign.style.border : "#000000"
    );
    setWidgetText(widgetDesign.style.widgetText);
    setGif(widgetDesign.gifUrl);
    setIsCustomGifChecked(widgetDesign.is_custom_gif);
  }, []);

  function uploadCustomGif(file) {
    setIsUploadingCustomGif(true);

    let partFormData = new FormData();
    partFormData.append("file", file);
    partFormData.append("_method", "patch");

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/update-custom-gif/${widgetDesign.id}`,
        partFormData,
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then((res) => {
        setGif(res.data.widget_design.gifUrl);
        updateCustomGif(res.data.widget_design.gifUrl);
        setIsUploadingCustomGif(false);
      })
      .catch((error) => console.log(error));
  }

  function removeCustomGif(flag) {
    if (!flag) {
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}/delete-custom-gif/${widgetDesign.id}`,
          axoisConfigUtil.getHeadersWithLangAndToken()
        )
        .then((res) => {
          setGif(res.data.default_gif_url);
          projectRemoveCustomGif(res.data.default_gif_url);
        })
        .catch((error) => console.log(error));
    }
  }

  function updatePosition(selectdPosition) {
    let updataedData = {
      widgetPosition: selectdPosition,
      widgetShape:
        widgetDesign.style.widgetShape === "CIRCLE" ? "CIRCLE" : "RECTANGULAR",
      hasBorder: widgetDesign.style.hasBorder,
      border: widgetDesign.style.border,
      hasWidgetText: widgetDesign.style.hasWidgetText,
      widgetText: widgetDesign.style.widgetText,
    };

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/widget-designs/${widgetDesign.id}`,
        updataedData,
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then((res) => {
        updateWidgetDesignStyle(updataedData);
      })
      .catch((error) => console.log(error));
  }

  function updateShape(selectdShape) {
    let updataedData = {
      widgetPosition: isLeft ? "LEFT" : "RIGHT",
      widgetShape: selectdShape,
      hasBorder: widgetDesign.style.hasBorder,
      border: widgetDesign.style.border,
      hasWidgetText: widgetDesign.style.hasWidgetText,
      widgetText: widgetDesign.style.widgetText,
    };

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/widget-designs/${widgetDesign.id}`,
        updataedData,
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then((res) => {
        updateWidgetDesignStyle(updataedData);
      })
      .catch((error) => console.log(error));
  }

  function updateCaption(text) {
    let updataedData = {
      widgetPosition: isLeft ? "LEFT" : "RIGHT",
      widgetShape:
        widgetDesign.style.widgetShape === "CIRCLE" ? "CIRCLE" : "RECTANGULAR",
      hasBorder: widgetDesign.style.hasBorder,
      border: widgetDesign.style.border,
      hasWidgetText: text.length == 0 ? false : true,
      widgetText: text,
    };

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/widget-designs/${widgetDesign.id}`,
        updataedData,
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then((res) => {
        updateWidgetDesignStyle(updataedData);
      })
      .catch((error) => console.log(error));
  }

  function updateBorderColor(hexColor) {
    let updataedData = {
      widgetPosition: isLeft ? "LEFT" : "RIGHT",
      widgetShape:
        widgetDesign.style.widgetShape === "CIRCLE" ? "CIRCLE" : "RECTANGULAR",
      hasBorder: true,
      border: hexColor,
      hasWidgetText: widgetDesign.style.hasWidgetText,
      widgetText: widgetDesign.style.widgetText,
    };

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/widget-designs/${widgetDesign.id}`,
        updataedData,
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then((res) => {
        updateWidgetDesignStyle(updataedData);
      })
      .catch((error) => console.log(error));
  }

  function removeBorderColor(flag) {
    if (!flag) {
      let updataedData = {
        widgetPosition: isLeft ? "LEFT" : "RIGHT",
        widgetShape:
          widgetDesign.style.widgetShape === "CIRCLE"
            ? "CIRCLE"
            : "RECTANGULAR",
        hasBorder: false,
        border: "",
        hasWidgetText: widgetDesign.style.hasWidgetText,
        widgetText: widgetDesign.style.widgetText,
      };

      axios
        .put(
          `${process.env.REACT_APP_API_URL}/widget-designs/${widgetDesign.id}`,
          updataedData,
          axoisConfigUtil.getHeadersWithLangAndToken()
        )
        .then((res) => {
          setColor("#000000");
          updateWidgetDesignStyle(updataedData);
        })
        .catch((error) => console.log(error));
    }
  }

  return (
    <>
      <p className='text-primary mx-[1.5rem] text-xl pt-8 font-light'>
        {t("brief.design")}
      </p>
      <div className='m-2 mt-20 flex'>
        <div className='w-3/5 flex flex-col gap-10'>
          <Accordion allowMultiple>
            <AccordionItem isDisabled>
              <h2 className='bg-[#f0fcff] rounded-lg'>
                <AccordionButton>
                  <Box as='span' flex='1' textAlign='left'>
                    <div className='flex items-center gap-4'>
                      <div>
                        <Image src={modalDesignIcon} />
                      </div>
                      <div className='flex items-center gap-4'>
                        <div>{t("projectDesign.modalDesign")}</div>
                        <p className='text-red-500'>
                          {t("projectDesign.soon")}
                        </p>
                      </div>
                    </div>
                  </Box>
                  <AccordionIcon color={"#00bcee"} boxSize={"12"} />
                </AccordionButton>
              </h2>
            </AccordionItem>
          </Accordion>
          <Accordion allowMultiple>
            <AccordionItem>
              <h2 className='bg-[#f0fcff] rounded-lg'>
                <AccordionButton>
                  <Box as='span' flex='1' textAlign='left'>
                    <div className='flex items-center gap-4'>
                      <div>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='60.17'
                          height='47.631'
                          viewBox='0 0 60.17 47.631'
                        >
                          <g
                            id='_2a5e2f9295963cae1dd98955ea238faf'
                            data-name='2a5e2f9295963cae1dd98955ea238faf'
                            transform='translate(-27.411 -77.263)'
                          >
                            <path
                              id='Path_166'
                              data-name='Path 166'
                              d='M84.877,123.894H30.116a1.7,1.7,0,0,1-1.7-1.7V79.968a1.7,1.7,0,0,1,1.7-1.7H84.877a1.7,1.7,0,0,1,1.7,1.7v42.221A1.7,1.7,0,0,1,84.877,123.894Z'
                              fill='none'
                              stroke='#474747'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='2'
                            />
                            <line
                              id='Line_6'
                              data-name='Line 6'
                              x2='57.676'
                              transform='translate(28.753 86.477)'
                              fill='none'
                              stroke='#474747'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='2'
                            />
                            <circle
                              id='Ellipse_2'
                              data-name='Ellipse 2'
                              cx='1.116'
                              cy='1.116'
                              r='1.116'
                              transform='translate(32.733 81.358)'
                              fill='#474747'
                            />
                            <circle
                              id='Ellipse_3'
                              data-name='Ellipse 3'
                              cx='1.116'
                              cy='1.116'
                              r='1.116'
                              transform='translate(36.692 81.358)'
                              fill='#474747'
                            />
                            <circle
                              id='Ellipse_4'
                              data-name='Ellipse 4'
                              cx='1.116'
                              cy='1.116'
                              r='1.116'
                              transform='translate(40.651 81.358)'
                              fill='#474747'
                            />
                            <g
                              id='Rectangle_103'
                              data-name='Rectangle 103'
                              transform='translate(70.392 103.56)'
                              fill='#00bcee'
                              stroke='#00bcee'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='2'
                            >
                              <rect width='13' height='18' stroke='none' />
                              <rect
                                x='1'
                                y='1'
                                width='11'
                                height='16'
                                fill='none'
                              />
                            </g>
                          </g>
                        </svg>
                      </div>
                      <div>{t("projectDesign.widgetDesign")}</div>
                    </div>
                  </Box>
                  <AccordionIcon color={"#00bcee"} boxSize={"12"} />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={5}>
                <div className='grid grid-cols-4 items-center p-4'>
                  <div>
                    <div className='font-medium'>
                      {t("projectDesign.direction")}
                    </div>
                  </div>
                  <div className='col-span-3' dir='ltr'>
                    <div className='flex gap-2'>
                      <div className='w-full'>
                        <button
                          className={`w-full py-1 px-4 lg:text-lg font-light rounded-full text-white ${isLeft ? "bg-primary" : "bg-secondary"
                            }`}
                          onClick={() => {
                            setIsLeft(true);
                            updatePosition("LEFT");
                          }}
                        >
                          <div>{t("projectDesign.sytle.LEFT")}</div>
                        </button>
                      </div>
                      <div className='w-full'>
                        <button
                          className={`w-full py-1 px-4 lg:text-lg font-light rounded-full text-white ${isLeft ? "bg-secondary" : "bg-primary"
                            }`}
                          onClick={() => {
                            setIsLeft(false);
                            updatePosition("RIGHT");
                          }}
                        >
                          <div>{t("projectDesign.sytle.RIGHT")}</div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='grid grid-cols-4 items-center p-4'>
                  <div>
                    <div className='font-medium'>
                      {t("projectDesign.shape")}
                    </div>
                  </div>
                  <div className='col-span-3'>
                    <div className='flex gap-2'>
                      <div className='w-full'>
                        <button
                          className={`w-full py-1 px-4 lg:text-lg font-light rounded-full text-white ${isRectangular ? "bg-primary" : "bg-secondary"
                            }`}
                          onClick={() => {
                            setIsRectangular(true);
                            updateShape("RECTANGULAR");
                          }}
                        >
                          <div>{t("projectDesign.sytle.RECTANGULAR")}</div>
                        </button>
                      </div>
                      <div className='w-full'>
                        <button
                          className={`w-full py-1 px-4 lg:text-lg font-light rounded-full text-white ${isRectangular ? "bg-secondary" : "bg-primary"
                            }`}
                          onClick={() => {
                            setIsRectangular(false);
                            updateShape("CIRCLE");
                          }}
                        >
                          <div>{t("projectDesign.sytle.CIRCLE")}</div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='grid grid-cols-4 items-center p-4'>
                  <div>
                    <div className='font-medium'>
                      {t("projectDesign.caption")}
                    </div>
                  </div>
                  <div className='col-span-3'>
                    <div className='flex w-1/2'>
                      <Input
                        value={widgetText}
                        maxLength={25}
                        placeholder={t("projectDesign.captionPlaceholder")}
                        onChange={(e) => {
                          setWidgetText(e.target.value);
                        }}
                        onBlur={() => {
                          updateCaption(widgetText);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='grid grid-cols-4 items-center p-4'>
                  <div>
                    <div className='font-medium'>
                      {t("projectDesign.borderColor")}
                    </div>
                  </div>
                  <div className='col-span-3'>
                    <div className='flex gap-4 items-center w-full'>
                      <div>
                        <Switch
                          checked={isColorChecked}
                          onChange={() => {
                            setIsColorChecked(!isColorChecked);
                            removeBorderColor(!isColorChecked);
                          }}
                          activeBoxShadow='0px 0px 0px 0px'
                          offColor='#323232'
                          onColor='#00bcee'
                          offHandleColor='#ffffff'
                          onHandleColor='#ffffff'
                          checkedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 15,
                              }}
                            ></div>
                          }
                          uncheckedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 15,
                              }}
                            ></div>
                          }
                        />
                      </div>
                      <div>
                        <button
                          className={`border-solid border-2 border-[#d8d7d7] pt-1 px-4 lg:text-lg rounded`}
                          disabled={!isColorChecked}
                        >
                          <div className='flex gap-2'>
                            <div>{color}</div>
                            <div>
                              <input
                                disabled={!isColorChecked}
                                type='color'
                                value={color}
                                onChange={(e) => setColor(e.target.value)}
                                onBlur={() => {
                                  updateBorderColor(color);
                                }}
                              />
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='grid grid-cols-4 items-center p-4'>
                  <div>
                    <div className='font-medium'>
                      {t("projectDesign.customGif")}
                    </div>
                  </div>
                  <div className='col-span-3'>
                    <div className='flex gap-4 items-center w-full'>
                      <div>
                        <Switch
                          checked={isCustomGifChecked}
                          onChange={() => {
                            setIsCustomGifChecked(!isCustomGifChecked);
                            removeCustomGif(!isCustomGifChecked);
                          }}
                          activeBoxShadow='0px 0px 0px 0px'
                          offColor='#323232'
                          onColor='#00bcee'
                          offHandleColor='#ffffff'
                          onHandleColor='#ffffff'
                          checkedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 15,
                              }}
                            ></div>
                          }
                          uncheckedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 15,
                              }}
                            ></div>
                          }
                        />
                      </div>
                      {
                        isCustomGifChecked ?
                          <div className='w-full'>
                            <input
                              id="gifUploadingId"
                              disabled={!isCustomGifChecked}
                              hidden={true}
                              type='file'
                              className='text-xs lg:text-base'
                              accept='image/gif'
                              onChange={(e) =>
                                uploadCustomGif(e.target.files[0])
                              }
                            />
                            <button className="w-fit lg:text-lg font-light rounded-full text-white bg-primary" >
                              <div>
                                <label className="block w-full cursor-pointer" htmlFor="gifUploadingId">
                                  <HStack spacing='12px' p={"2.5"} paddingX={"4"}>
                                    {isUploadingCustomGif ? <CircularProgress isIndeterminate color='blue.300' size='30px' /> : <AiOutlineCloudUpload size='30px' />}

                                    <Text>
                                      {t("projectDesign.uploadGif")}
                                    </Text>
                                  </HStack>
                                </label>
                              </div>
                            </button>
                          </div>
                          : null
                      }
                    </div>
                  </div>
                </div>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <div className='w-2/5 h-fit m-2 relative'>
          <Image
            src={previewWidgetDesign}
            alt='previewWidgetDesign'
            dir='ltr'
          />
          <div
            id='activid-widget-id'
            className={` ${isRectangular ? "w-[50px]" : "w-[70px]"} h-[70px] absolute bottom-1 m-2 rounded-lg ${isLeft && language === languageConstants.EN ? "left-0" : ""} ${!isLeft && language === languageConstants.EN ? "right-0" : ""} ${isLeft && language === languageConstants.AR ? "left-0" : ""} ${!isLeft && language === languageConstants.AR ? "right-0" : ""} `}
          >
            <div>
              <svg
                className='bg-white text-black rounded-[9999px] h-[1rem] w-[1rem] right-[-8px] top-[-7px] z-10 absolute'
                stroke='currentColor'
                fill='currentColor'
                viewBox='0 0 1024 1024'
                height='1em'
                width='1em'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 0 1-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z'></path>
              </svg>
            </div>
            <div
              className={`relative w-full h-full ${isRectangular ? "rounded-lg" : "rounded-full"
                } `}
              style={isColorChecked ? { border: "solid 2px " + color } : null}
            >

              {
                isLoading && !isRectangular ?
                  <SkeletonCircle size='20' />
                  : null
              }
              {
                isLoading && isRectangular ?
                  <Skeleton height='70px' />
                  : null
              }

              <Image
                hidden={isLoading}
                className={`w-full h-full ${isRectangular ? "rounded-md" : "rounded-full"} `}
                src={gif}
                onError={() => setIsLoading(false)}
                onLoad={() => { setIsLoading(false); }}
                fallbackSrc={gifLoadingIcon}
              />
              <div
                id='activid-widget-text-id'
                className='absolute top-1/2 left-1/2 text-center w-full text-[10px] -translate-x-2/4 -translate-y-2/4 text-white'
              >
                {widgetText}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectDesign;
