import { Box, Button, Center, HStack, Text, useDisclosure } from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import Part from "./Part";
import { IoIosAddCircle } from "react-icons/io";
import FileUploaderForPart from "../dashboard/FileUploaderForPart";
import Loader from "../../Loader";
import Preview from "./Preview";
import PartsList from "./PartsList";
import videosConstants from "../../../constants/VideosConstants";

export default function Building({
  t,
  enabledTypes,
  project,
  appNewPart,
  removePartById,
  setProject,
  language,
}) {
  const [part, setPart] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activePart, setActivePart] = useState(null);
  const [previewLoaderFlag, setPreviewLoaderFlag] = useState(true);
  const [previewOptions, setPreviewOptions] = useState([]);

  useEffect(() => {
    if (part === null && activePart === null && project.parts.length) {
      setPart(project.parts[0]);
      setActivePart(project.parts[0].id);
    }
  }, []);

  return (
    <>
      <p className='text-primary pt-8 text-xl pb-2 mx-[1.5rem] font-light'>
        {t("brief.build")}
      </p>
      <div className='flex'>
        {project.parts.length === 0 ? (
          <Center height='300px' w='full'>
            <FileUploaderForPart
              projectId={project.id}
              isOpen={isOpen}
              onClose={onClose}
              appNewPart={appNewPart}
              setProject={setProject}
            />
            {project.parts.length < videosConstants.MAX_FILES ? (
              <Button
                backgroundColor='#00bcee'
                background='#00bcee'
                variant='#00bcee'
                color='white'
                _disabled={{
                  bg: "#00bcee",
                  _hover: {
                    bg: "#00bcee",
                    opacity: "0.4",
                    cursor: "not-allowed",
                  },
                }}
                rounded={"full"}
                fontWeight={"light"}
                className='flex items-center gap-2'
                onClick={onOpen}
                loadingText={t("building.addButton")}
              >
                <IoIosAddCircle className='text-2xl' />
                <Text>{t("building.addVideo")}</Text>
              </Button>
            ) : (
              <Button
                title={t("partsList.disabledAddVideo1") + " " + videosConstants.MAX_FILES + " " + t("partsList.disabledAddVideo2")}
                opacity='0.4'
                backgroundColor='#00bcee'
                background='#00bcee'
                variant='#00bcee'
                color='white'
                _disabled={{
                  bg: "#00bcee",
                  _hover: {
                    bg: "#00bcee",
                    opacity: "0.4",
                    cursor: "not-allowed",
                  },
                }}
                rounded={"full"}
                fontWeight={"light"}
                className='flex items-center gap-2'
                isDisabled={true}
              >
                <IoIosAddCircle className='text-2xl' />
                <Text>{t("building.addVideo")}</Text>
              </Button>
            )}
          </Center>
        ) : project?.parts ? (
          <div className='w-full'>
            {activePart != null ? (
              <HStack className='w-full m-2'>
                <Box className='w-2/3'>
                  <Part
                    project={project}
                    setProject={setProject}
                    part={part}
                    setPart={setPart}
                    t={t}
                    enabledTypes={enabledTypes}
                    removePartById={removePartById}
                    activePart={activePart}
                    setPreviewOptions={setPreviewOptions}
                  />
                </Box>
                <Box className='w-1/3'>
                  <Preview
                    path={part.video.path}
                    options={previewOptions}
                    previewLoaderFlag={previewLoaderFlag}
                    setPreviewLoaderFlag={setPreviewLoaderFlag}
                  />
                </Box>
              </HStack>
            ) : null}
            <PartsList
              project={project}
              t={t}
              activePart={activePart}
              setActivePart={setActivePart}
              setPart={setPart}
              appNewPart={appNewPart}
              removePartById={removePartById}
              language={language}
              setPreviewLoaderFlag={setPreviewLoaderFlag}
              setProject={setProject}
            />
          </div>
        ) : (
          <Center height='300px' w='full'>
            <Loader isLoading={true} />
          </Center>
        )}
      </div>
    </>
  );
}
