import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import axoisConfigUtil from '../utils/AxoisConfigUtil';

export const useGetProjects = () => {
    return useQuery(['projects'], () => {
        return axios
            .get(
                `${process.env.REACT_APP_API_URL}/projects`,
                axoisConfigUtil.getHeadersWithLangAndToken()
            )
            .then((res) => res.data.project)
    });
}
