import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, HStack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import RulesItem from "./RulesItem";

export default function Rules({ t, project, projectRuleCategories, updateProjectRules }) {
  const [rulesList, setRulesList] = useState([]);
  const [inProgressToAddRule, setInProgressToAddRule] = useState(false);

  useEffect(() => {
    setRulesList(project.rules);
  }, []);

  const handleNewRule = () => {
    setInProgressToAddRule(true);

    const temp = [...rulesList];
    const newRule = {
      project_id: project.id,
      project_rule_category_id: projectRuleCategories[0].id, //TODO: should be enhanced from ( back-end & front-end)
      project_rule_condition_id: projectRuleCategories[0].conditions[0].id,  //TODO: should be enhanced from ( back-end & front-end) 
      value: "",
    };
    temp.push(newRule);
    setRulesList(temp);
  };

  return (
    <>
      <p className='text-primary pt-8 text-xl pb-2 px-[1.5rem] font-light'>
        {t("brief.rules")}
      </p>
      <Box mt='2rem' ms='1rem' fontSize='1.125rem' className="w-3/5">
        <Accordion allowMultiple>
          <AccordionItem>
            <h2 className='bg-[#f0fcff] rounded-lg'>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left'>
                  <div className='flex items-center gap-4'>
                    <div>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='60.17'
                        height='47.631'
                        viewBox='0 0 60.17 47.631'
                      >
                        <g
                          id='_2a5e2f9295963cae1dd98955ea238faf'
                          data-name='2a5e2f9295963cae1dd98955ea238faf'
                          transform='translate(-27.411 -77.263)'
                        >
                          <path
                            id='Path_166'
                            data-name='Path 166'
                            d='M84.877,123.894H30.116a1.7,1.7,0,0,1-1.7-1.7V79.968a1.7,1.7,0,0,1,1.7-1.7H84.877a1.7,1.7,0,0,1,1.7,1.7v42.221A1.7,1.7,0,0,1,84.877,123.894Z'
                            fill='none'
                            stroke='#474747'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                          />
                          <line
                            id='Line_6'
                            data-name='Line 6'
                            x2='57.676'
                            transform='translate(28.753 86.477)'
                            fill='none'
                            stroke='#474747'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                          />
                          <circle
                            id='Ellipse_2'
                            data-name='Ellipse 2'
                            cx='1.116'
                            cy='1.116'
                            r='1.116'
                            transform='translate(32.733 81.358)'
                            fill='#474747'
                          />
                          <circle
                            id='Ellipse_3'
                            data-name='Ellipse 3'
                            cx='1.116'
                            cy='1.116'
                            r='1.116'
                            transform='translate(36.692 81.358)'
                            fill='#474747'
                          />
                          <circle
                            id='Ellipse_4'
                            data-name='Ellipse 4'
                            cx='1.116'
                            cy='1.116'
                            r='1.116'
                            transform='translate(40.651 81.358)'
                            fill='#474747'
                          />
                          <g
                            id='Rectangle_103'
                            data-name='Rectangle 103'
                            transform='translate(70.392 103.56)'
                            fill='#00bcee'
                            stroke='#00bcee'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                          >
                            <rect width='13' height='18' stroke='none' />
                            <rect
                              x='1'
                              y='1'
                              width='11'
                              height='16'
                              fill='none'
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div>
                      {t("projectRules.pages")}
                    </div>
                  </div>
                </Box>
                <AccordionIcon color={"#00bcee"} boxSize={"12"} />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={5} >
              <HStack spacing='24px' justifyContent={"space-between"}>
                <Box>
                  <Text>
                    {t("projectRules.pagesDescription")}
                  </Text>
                </Box>
                <Box>
                  {
                    !inProgressToAddRule ?
                      <Button backgroundColor='#00bcee' background='#00bcee' variant='#00bcee' color='white' _disabled={{ bg: "#00bcee", _hover: { bg: "#00bcee", opacity: "0.4", cursor: "not-allowed" } }} rounded={"full"} fontWeight={"light"} className="flex items-center gap-2"
                        onClick={handleNewRule}
                        loadingText={t("building.addButton")}>
                        <IoIosAddCircle className='text-2xl' />
                        <Text>{t("projectRules.add")}</Text>
                      </Button>
                      : null
                  }
                </Box>
              </HStack>

              {
                rulesList.map((rule) => {
                  return (
                    <RulesItem
                      key={rule.id}
                      rule={rule}
                      rulesList={rulesList}
                      setRulesList={setRulesList}
                      t={t}
                      id={rule.id}
                      inProgressToAddRule={inProgressToAddRule}
                      setInProgressToAddRule={setInProgressToAddRule}
                      updateProjectRules={updateProjectRules}
                    />
                  );
                })
              }
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </>
  );
}
