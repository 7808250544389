import { IoIosAddCircle } from "react-icons/io";
import FileUploaderForPart from "../dashboard/FileUploaderForPart";
import { useDisclosure } from "@chakra-ui/hooks";
import { Image } from "@chakra-ui/image";
import { Button } from "@chakra-ui/button";
import axios from "axios";
import axoisConfigUtil from "../../../utils/AxoisConfigUtil";
import { useState } from "react";
import {
  Box,
  Center,
  Container,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { RiSubtractFill } from "react-icons/ri";
import DeletePartDialog from "./dialogs/confirm/DeletePartDialog";
import videosConstants from "../../../constants/VideosConstants";

export default function PartsList({
  project,
  t,
  activePart,
  setActivePart,
  setPart,
  appNewPart,
  removePartById,
  language,
  setPreviewLoaderFlag,
  setProject,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [partSelectedToDeleteId, setPartSelectedToDeleteId] = useState(null);
  const {
    isOpen: removePartDialogIsOpen,
    onOpen: removePartDialogOnOpen,
    onClose: removePartDialogOnClose,
  } = useDisclosure();

  const handleActivePart = (ele) => {
    setPreviewLoaderFlag(true);
    const newPart = { ...ele };
    setActivePart(ele.id);
    setPart(newPart);
  };

  function removePart(partId) {
    setPartSelectedToDeleteId(partId);
    removePartDialogOnOpen();
  }

  return (
    <>
      <DeletePartDialog
        language={language}
        isOpen={removePartDialogIsOpen}
        onClose={removePartDialogOnClose}
        partSelectedToDeleteId={partSelectedToDeleteId}
        setActivePart={setActivePart}
        removePartById={removePartById}
      />

      <FileUploaderForPart
        projectId={project.id}
        isOpen={isOpen}
        onClose={onClose}
        appNewPart={appNewPart}
        setProject={setProject}
      />

      <div className='m-2'>
        <Flex align={"center"}>
          <p className='w-32 text-lg'>{t("building.videos")}</p>
          {project.parts.length < videosConstants.MAX_FILES ? (
            <Button
              backgroundColor='#00bcee'
              background='#00bcee'
              variant='#00bcee'
              color='white'
              _disabled={{
                bg: "#00bcee",
                _hover: {
                  bg: "#00bcee",
                  opacity: "0.4",
                  cursor: "not-allowed",
                },
              }}
              rounded={"full"}
              fontWeight={"light"}
              className='flex items-center gap-2'
              onClick={onOpen}
              loadingText={t("building.addButton")}
            >
              <IoIosAddCircle className='text-2xl' />
              <Text>{t("building.addVideo")}</Text>
            </Button>
          ) : (
            <Button
              title={t("partsList.disabledAddVideo1") + " " + videosConstants.MAX_FILES + " " + t("partsList.disabledAddVideo2")}
              opacity='0.4'
              backgroundColor='#00bcee'
              background='#00bcee'
              variant='#00bcee'
              color='white'
              _disabled={{
                bg: "#00bcee",
                _hover: {
                  bg: "#00bcee",
                  opacity: "0.4",
                  cursor: "not-allowed",
                },
              }}
              rounded={"full"}
              fontWeight={"light"}
              className='flex items-center gap-2'
              isDisabled={true}
            >
              <IoIosAddCircle className='text-2xl' />
              <Text>{t("building.addVideo")}</Text>
            </Button>
          )}
        </Flex>
        <div className='flex flex-row gap-5 mx-[0.5rem] mt-4'>
          {project.parts.map((ele, index) => {
            return (
              <div key={ele.id} className='justify-center items-center'>
                <div
                  key={index}
                  className={`h-32 w-20 ${activePart === ele.id
                    ? "border-4 border-[#00c0f3]"
                    : "border border-black"
                    } rounded-lg relative`}
                >
                  <RiSubtractFill
                    onClick={() => {
                      removePart(ele.id);
                    }}
                    className='absolute -left-3 -top-3 text-white bg-primary rounded-sm w-4 h-4 border border-black cursor-pointer'
                  />

                  <Container
                    backgroundImage={ele.part_img}
                    w='full'
                    h='full'
                    backgroundSize='cover'
                    backgroundRepeat='no-repeat'
                    backgroundPosition='center'
                    backgroundColor='#00bcee'
                    borderRadius={activePart === ele.id ? "0" : "8px"}
                    onClick={() => handleActivePart(ele)}
                    cursor='pointer'
                  />
                </div>
                <p className='text-center text-gray-500 text-sm'>{++index}</p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
