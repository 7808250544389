import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import toastifyFile from "../external-libraries/React-toastify/index";
import errorConstants from "../../constants/ErrorConstants";
import AuthHeader from "./AuthHeader";
import axoisConfigUtil from "../../utils/AxoisConfigUtil";

import { useFormik } from "formik";
import * as Yup from "yup";
import {
  InputGroup,
  Input,
  InputRightElement,
  InputLeftElement,
  Stack,
  InputLeftAddon,
  Text,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Tfoot,
  Link,
  Tag,
  Flex,
  InputRightAddon,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

import { BsCheck2Circle } from "react-icons/bs";
import { BiError } from "react-icons/bi";
import { BsTwitter } from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io";
import languageConstants from "../../constants/LanguageConstants";
import { useEffect } from "react";
import PlatformsConstants from "../../constants/PlatformsConstants";
import settingsConstants from "../../constants/SettingsConstants";

function CreateStore({ language, setLanguage }) {
  let navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `${t("title.projectName")} | ${t("home.signUp")}`;
  }, []);

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      name: "",
      email: "",
      phone: "",
      password: "",
      passwordConfirmation: "",
      storeName: "",
      storeUrl: "",
      terms: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("formik.required")),
      email: Yup.string()
        .email(t("formik.email"))
        .required(t("formik.required")),
      phone: Yup.number()
        .required(t("formik.required")),
      password: Yup.string()
        .required(t("formik.required"))
        .min(8, t("formik.minPassword")),
      passwordConfirmation: Yup.string()
        .required(t("formik.required"))
        .oneOf([Yup.ref("password"), null], t("formik.passwordConfirmation")),
      storeName: Yup.string()
        .required(t("formik.required")),
      storeUrl: Yup.string()
        .url(t("formik.validURL"))
        .required(t("formik.required")),

      terms: Yup.boolean().oneOf([true], t("formik.terms")),
    }),
    onSubmit: (values) => {
      signupSubmit();
    },
  });

  function signupSubmit() {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/store-user/create`,
        {
          name: formik.values.name,
          email: formik.values.email,
          phone: formik.values.phone,
          phone_country: "SA",
          store_name: formik.values.storeName,
          store_url: formik.values.storeUrl,
          password: formik.values.password,
          password_confirmation: formik.values.passwordConfirmation,
        },
        axoisConfigUtil.getHeadersWithLang()
      )
      .then(function (response) {
        toastifyFile.successNotify(
          t("signUp.messages.success.accountCreatedSuccessfully")
        );
        toastifyFile.successNotify(
          t("login.messages.success.loggedInSuccessfully")
        );
        localStorage.setItem("token", response.data.user.access_token);
        localStorage.setItem("user", JSON.stringify(response.data.user.user));
        navigate("/dashboard");
      })
      .catch(function (error) {
        if (!error.response) {
          // network error
          toastifyFile.errorNotify(
            t("general.messages.error.connectionRefused") +
            " : " +
            errorConstants.signUpPage.code
          );
        }
        if (error.response.status === 400) {
          if (error.response?.data?.errors?.email) {
            formik.setErrors({ email: error.response.data.errors.email });
          }
          toastifyFile.errorNotify(error.response.data.message);
        } else {
          toastifyFile.errorNotify(
            t("general.messages.error.generalError") +
            " : " +
            errorConstants.signUpPage.code
          );
        }
      });
  }

  return (
    <>
      <div className='min-h-screen pb-5 flex flex-col gap-8 items-center bg-[#ebf7fd] custom-bg text-gray-500'>
        <AuthHeader
          pageType='signup'
          language={language}
          setLanguage={setLanguage}
        />

        <div className='md:w-1/2 w-3/4 my-auto'>
          <form onSubmit={formik.handleSubmit}>
            <div className='flex flex-col gap-2 p-7 bg-white rounded-tr-2xl rounded-bl-2xl'>
              <Stack spacing={4}>
                <label className='font-light text-xl' htmlFor='name'>
                  {t("signUp.name")}
                </label>
                <InputGroup>
                  <Input
                    id='nameId'
                    name='name'
                    type='text'
                    required
                    isInvalid={formik.errors.name && formik.touched.name}
                    errorBorderColor='red.300'
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                  />
                  {language === languageConstants.AR ? (
                    <InputLeftElement
                      children={
                        !formik.errors.name && formik.touched.name ? (
                          <BsCheck2Circle className='text-green-700' />
                        ) : formik.errors.name && formik.touched.name ? (
                          <BiError className='text-red-400' />
                        ) : (
                          <></>
                        )
                      }
                    />
                  ) : (
                    <InputRightElement
                      children={
                        !formik.errors.name && formik.touched.name ? (
                          <BsCheck2Circle className='text-green-700' />
                        ) : formik.errors.name && formik.touched.name ? (
                          <BiError className='text-red-400' />
                        ) : (
                          <></>
                        )
                      }
                    />
                  )}
                </InputGroup>
                {formik.errors.name && formik.touched.name && (
                  <div className='text-red-400'>* {formik.errors.name}</div>
                )}

                <label className='font-light text-xl' htmlFor='email'>
                  {t("signUp.email")}
                </label>
                <InputGroup>
                  <Input
                    id='emailId'
                    name='email'
                    type='mail'
                    required
                    isInvalid={formik.errors.email && formik.touched.email}
                    errorBorderColor='red.300'
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                  />
                  {language === languageConstants.AR ? (
                    <InputLeftElement
                      children={
                        !formik.errors.email && formik.touched.email ? (
                          <BsCheck2Circle className='text-green-700' />
                        ) : formik.errors.email && formik.touched.email ? (
                          <BiError className='text-red-400' />
                        ) : (
                          <></>
                        )
                      }
                    />
                  ) : (
                    <InputRightElement
                      children={
                        !formik.errors.email && formik.touched.email ? (
                          <BsCheck2Circle className='text-green-700' />
                        ) : formik.errors.email && formik.touched.email ? (
                          <BiError className='text-red-400' />
                        ) : (
                          <></>
                        )
                      }
                    />
                  )}
                </InputGroup>
                {formik.errors.email && formik.touched.email && (
                  <div className='text-red-400'>* {formik.errors.email}</div>
                )}

                <label className='font-light text-xl' htmlFor='phoneId'>
                  {t("signUp.phone")}
                </label>
                {/* <InputGroup>
                  {language === languageConstants.AR ? (
                    <InputRightAddon>+966</InputRightAddon>
                  ) : (
                    <InputLeftAddon>+966</InputLeftAddon>
                  )}
                  <Input id='phoneId' name='phone' type='tel' placeholder='530000000' maxLength={9} />
                </InputGroup> */}
                <InputGroup>
                  {language === languageConstants.AR ? (
                    <InputRightAddon>+966</InputRightAddon>
                  ) : (
                    <InputLeftAddon>+966</InputLeftAddon>
                  )}
                  <Input
                    id='phoneId'
                    name='phone'
                    type='tel'
                    placeholder='530000000'
                    maxLength={9}
                    required
                    isInvalid={formik.errors.phone && formik.touched.phone}
                    errorBorderColor='red.300'
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                  />
                  {language === languageConstants.AR ? (
                    <InputLeftElement
                      children={
                        !formik.errors.phone && formik.touched.phone ? (
                          <BsCheck2Circle className='text-green-700' />
                        ) : formik.errors.phone && formik.touched.phone ? (
                          <BiError className='text-red-400' />
                        ) : (
                          <></>
                        )
                      }
                    />
                  ) : (
                    <InputRightElement
                      children={
                        !formik.errors.phone && formik.touched.phone ? (
                          <BsCheck2Circle className='text-green-700' />
                        ) : formik.errors.phone && formik.touched.phone ? (
                          <BiError className='text-red-400' />
                        ) : (
                          <></>
                        )
                      }
                    />
                  )}
                </InputGroup>
                {formik.errors.phone && formik.touched.phone && (
                  <div className='text-red-400'>* {formik.errors.phone}</div>
                )}



                <label className='font-light text-xl' htmlFor='storeNameId'>
                  {t("signUp.storeName")}
                </label>
                <InputGroup>
                  <Input
                    id='storeNameId'
                    name='storeName'
                    type='text'
                    required
                    isInvalid={formik.errors.storeName && formik.touched.storeName}
                    errorBorderColor='red.300'
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                  />
                  {language === languageConstants.AR ? (
                    <InputLeftElement
                      children={
                        !formik.errors.storeName && formik.touched.storeName ? (
                          <BsCheck2Circle className='text-green-700' />
                        ) : formik.errors.storeName && formik.touched.storeName ? (
                          <BiError className='text-red-400' />
                        ) : (
                          <></>
                        )
                      }
                    />
                  ) : (
                    <InputRightElement
                      children={
                        !formik.errors.storeName && formik.touched.storeName ? (
                          <BsCheck2Circle className='text-green-700' />
                        ) : formik.errors.storeName && formik.touched.storeName ? (
                          <BiError className='text-red-400' />
                        ) : (
                          <></>
                        )
                      }
                    />
                  )}
                </InputGroup>
                {formik.errors.storeName && formik.touched.storeName && (
                  <div className='text-red-400'>* {formik.errors.storeName}</div>
                )}



                <label className='font-light text-xl' htmlFor='storeUrlId'>
                  {t("signUp.storeUrl")}
                </label>
                <InputGroup>
                  <Input
                    id='storeUrlId'
                    name='storeUrl'
                    type='text'
                    required
                    isInvalid={formik.errors.storeUrl && formik.touched.storeUrl}
                    errorBorderColor='red.300'
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                  />
                  {language === languageConstants.AR ? (
                    <InputLeftElement
                      children={
                        !formik.errors.storeUrl && formik.touched.storeUrl ? (
                          <BsCheck2Circle className='text-green-700' />
                        ) : formik.errors.storeUrl && formik.touched.storeUrl ? (
                          <BiError className='text-red-400' />
                        ) : (
                          <></>
                        )
                      }
                    />
                  ) : (
                    <InputRightElement
                      children={
                        !formik.errors.storeUrl && formik.touched.storeUrl ? (
                          <BsCheck2Circle className='text-green-700' />
                        ) : formik.errors.storeUrl && formik.touched.storeUrl ? (
                          <BiError className='text-red-400' />
                        ) : (
                          <></>
                        )
                      }
                    />
                  )}
                </InputGroup>
                {formik.errors.storeUrl && formik.touched.storeUrl && (
                  <div className='text-red-400'>* {formik.errors.storeUrl}</div>
                )}

                <label className='font-light text-xl' htmlFor='password'>
                  {t("signUp.password")}
                </label>
                <InputGroup>
                  <Input
                    id='PasswordId'
                    name='password'
                    type='password'
                    required
                    isInvalid={
                      formik.errors.password && formik.touched.password
                    }
                    errorBorderColor='red.300'
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                  />
                  {language === languageConstants.AR ? (
                    <InputLeftElement
                      children={
                        !formik.errors.password && formik.touched.password ? (
                          <BsCheck2Circle className='text-green-700' />
                        ) : formik.errors.password &&
                          formik.touched.password ? (
                          <BiError className='text-red-400' />
                        ) : (
                          <></>
                        )
                      }
                    />
                  ) : (
                    <InputRightElement
                      children={
                        !formik.errors.password && formik.touched.password ? (
                          <BsCheck2Circle className='text-green-700' />
                        ) : formik.errors.password &&
                          formik.touched.password ? (
                          <BiError className='text-red-400' />
                        ) : (
                          <></>
                        )
                      }
                    />
                  )}
                </InputGroup>
                {formik.errors.password && formik.touched.password && (
                  <div className='text-red-400'>* {formik.errors.password}</div>
                )}

                <label
                  className='font-light text-xl'
                  htmlFor='passwordConfirmation'
                >
                  {t("signUp.passwordConfirmation")}
                </label>
                <InputGroup>
                  <Input
                    id='passwordConfirmationId'
                    name='passwordConfirmation'
                    type='password'
                    required
                    isInvalid={
                      formik.errors.passwordConfirmation &&
                      formik.touched.passwordConfirmation
                    }
                    errorBorderColor='red.300'
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                  />
                  {language === languageConstants.AR ? (
                    <InputLeftElement
                      children={
                        !formik.errors.passwordConfirmation &&
                          formik.touched.passwordConfirmation ? (
                          <BsCheck2Circle className='text-green-700' />
                        ) : formik.errors.passwordConfirmation &&
                          formik.touched.passwordConfirmation ? (
                          <BiError className='text-red-400' />
                        ) : (
                          <></>
                        )
                      }
                    />
                  ) : (
                    <InputRightElement
                      children={
                        !formik.errors.passwordConfirmation &&
                          formik.touched.passwordConfirmation ? (
                          <BsCheck2Circle className='text-green-700' />
                        ) : formik.errors.passwordConfirmation &&
                          formik.touched.passwordConfirmation ? (
                          <BiError className='text-red-400' />
                        ) : (
                          <></>
                        )
                      }
                    />
                  )}
                </InputGroup>
                {formik.errors.passwordConfirmation &&
                  formik.touched.passwordConfirmation && (
                    <div className='text-red-400'>
                      * {formik.errors.passwordConfirmation}
                    </div>
                  )}



                <div className='flex gap-2 font-light mt-2'>
                  <input
                    type='checkbox'
                    name='terms'
                    id='terms'
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                  />
                  <label htmlFor='terms'>
                    {t("signUp.iAgreeToThe")}{" "}
                    <span
                      className='text-left text-[#4050e0] text-sm cursor-pointer w-fit'
                      onClick={() => {
                        navigate("/terms-of-use");
                      }}
                    >
                      {t("signUp.termsAndConditions")}
                    </span>{" "}
                    {t("signUp.and")}{" "}
                    <span
                      className='text-left text-[#4050e0] text-sm cursor-pointer w-fit'
                      onClick={() => {
                        navigate("/privacy-policy");
                      }}
                    >
                      {t("signUp.privacyPolicy")}
                    </span>
                  </label>
                </div>
                {formik.errors.terms && formik.touched.terms && (
                  <div className='text-red-400'>* {formik.errors.terms}</div>
                )}
              </Stack>
            </div>
            <button
              type='submit'
              className='w-1/3 hover:w-[37%] transition-all mx-auto mt-8 block py-2 text-lg font-light rounded-full text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary'
            >
              {t("signUp.register")}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default CreateStore;
