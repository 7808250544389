import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  Code,
  Container,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import projectStatusConstants from "../../../constants/ProjectStatusConstants";
import { useEffect, useState } from "react";
import { FaCircle } from "react-icons/fa";
import { MdContentCopy } from "react-icons/md";
import axios from "axios";
import axoisConfigUtil from "../../../utils/AxoisConfigUtil";
import languageConstants from "../../../constants/LanguageConstants";
import { useUserContext } from "../../../context/userContext";
import party from "party-js";
import toastifyFile from "../../external-libraries/React-toastify/index";
import Countdown from "react-countdown";
import errorConstants from "../../../constants/ErrorConstants";
import settingsConstants from "../../../constants/SettingsConstants";
import PlatformsConstants from "../../../constants/PlatformsConstants";


function ProjectDeploy({
  t,
  language,
  projectId,
  projectStatus,
  platformIdentify,
  publishId,
  setActiveTab,
  notAllowedToNavigateTabs,
  setNotAllowedToNavigateTabs,
  updateProjectStatus,
}) {
  const [status, setStatus] = useState(projectStatusConstants.READY);

  const [showScript, setShowScript] = useState(false);
  const [script, setScript] = useState("");
  const [isDisabledBtn, setIsDisabledBtn] = useState(false);
  const [isChangeStatusLoading, setIsChangeStatusLoading] = useState(false);
  const [disabledSeconds, setDisabledSeconds] = useState(59000);
  const [verifyEmailDisabledBtn, setVerifyEmailDisabledBtn] = useState(false);
  const { user, setUserData } = useUserContext();
  const [actividPublishId, setActividPublishId] = useState(publishId == null ? "" : publishId);

  useEffect(() => {
    handelStatus();
    let date = new Date(localStorage.getItem("sendEmailVerificationCurrentDate"));
    if (new Date() < new Date(date)) {
      setDisabledSeconds(new Date(date) - new Date());
      setIsDisabledBtn(true);
    }
  }, []);

  useEffect(() => {
    let date = new Date(localStorage.getItem("sendEmailVerificationCurrentDate"));
    if (new Date() < new Date(date)) {
      setDisabledSeconds(new Date(date) - new Date());
      setIsDisabledBtn(true);
    }
  }, [verifyEmailDisabledBtn]);

  useEffect(() => {
    if (user.isVerified) {
      handelStatus();
      setIsDisabledBtn(false);
    } else {
    }
  }, [user.isVerified]);


  useEffect(() => {
    if (projectStatusConstants.DEPLOYED.key == projectStatus) {
      setScript(
        `
      <script>
      window.actividPublishId = '${actividPublishId}'
      let style_link = document.createElement("link");
      style_link.rel = "stylesheet";
      style_link.href = "https://base.acti-vide.com/activid-main.css";
      document.head.append(style_link);
  
      let script = document.createElement("script");
      script.src = "https://base.acti-vide.com/activid-event.js";
      script.async = true;
      script.defer = true;
      document.head.append(script);
      </script>
      `
      );
      setShowScript(true);
    }
  }, [actividPublishId]);

  const renderer = ({ hours, minutes, seconds, completed }) => {
    return <Button
      isDisabled={true}
      className='w-fit'
      borderRadius='87px 87px 87px 87px'
    >
      {t("projectDeploy.retryAgain")} {seconds}
    </Button>;
  };

  const verifyEmailRenderer = ({ hours, minutes, seconds, completed }) => {
    return <Button
      isDisabled={true}
      className='w-fit'
      borderRadius='87px 87px 87px 87px'
    >
      {t("projectDeploy.retryAgain")} {seconds}
    </Button>;
  };

  function updateStatus(pStatus) {
    updateProjectStatus(pStatus);
  }

  function handelStatus() {
    if (!user.isVerified) {
      setStatus(projectStatusConstants.USER_NOT_VERIFIED);
    } else {
      switch (projectStatus) {
        case projectStatusConstants.NOT_COMPLETE.key:
          setStatus(projectStatusConstants.NOT_COMPLETE);
          break;
        case projectStatusConstants.READY.key:
          setStatus(projectStatusConstants.READY);
          break;
        case projectStatusConstants.DEPLOYED.key:
          {
            setStatus(projectStatusConstants.DEPLOYED);
            getProjectaScriptByPlatformId(platformIdentify);
          }
          break;
        case projectStatusConstants.SUSPENDED.key:
          {
            setStatus(projectStatusConstants.SUSPENDED);
          }
          break;
        case projectStatusConstants.SUSPENDED_BY_SYSTEM.key:
          {
            setStatus(projectStatusConstants.SUSPENDED_BY_SYSTEM);
          }
          break;
        default: {
          if (projectStatus.startsWith(projectStatusConstants.STOPPED.key)) {
            setStatus(projectStatusConstants.STOPPED);
          } else {
            // TODO : handel other status , or restarcure/rebuild the logic of code of method ( handelStatus )
          }
        }
      }
    }
  }

  function doAction() {
    switch (status.key) {
      case projectStatusConstants.NOT_COMPLETE.key:
        setActiveTab(0);
        break;
      case projectStatusConstants.READY.key:
        deployProject();
        break;
      case projectStatusConstants.DEPLOYED.key:
        suspendProject();
        break;
      case projectStatusConstants.SUSPENDED.key:
        deployProject();
        break;
      case projectStatusConstants.SUSPENDED_BY_SYSTEM.key:
        contactUs();
        break;
      case projectStatusConstants.USER_NOT_VERIFIED.key:
        sendEmailVerification();
        break;
      default: {
        if (status.key.startsWith(projectStatusConstants.STOPPED.key)) {
          navigateToSalla();
        } else {
          // TODO : handel other status , or restarcure/rebuild the logic of code of method ( doAction )
        }
      }
    }
  }

  function deployProject() {
    setIsChangeStatusLoading(true);

    let projectStatus = new FormData();
    projectStatus.append("status", "DEPLOYED");
    projectStatus.append("_method", "patch");

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/projects/${projectId}/change-status`,
        projectStatus,
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then((res) => {
        setIsChangeStatusLoading(false);
        setStatus(projectStatusConstants.DEPLOYED);
        setActividPublishId(res.data.publish_id)
        getProjectaScriptByPlatformId(platformIdentify);
        updateStatus(projectStatusConstants.DEPLOYED.key);
        setNotAllowedToNavigateTabs(true);
      })
      .catch((error) => {
        setIsChangeStatusLoading(false);
        toastifyFile.errorNotify(error.response.data.error + " : " + errorConstants.projectDeploy.code);
      });
  }

  function suspendProject() {
    setIsChangeStatusLoading(true);

    let projectStatus = new FormData();
    projectStatus.append("status", "SUSPENDED");
    projectStatus.append("_method", "patch");

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/projects/${projectId}/change-status`,
        projectStatus,
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then((res) => {
        setIsChangeStatusLoading(false);

        setStatus(projectStatusConstants.SUSPENDED);
        setShowScript(false);
        setNotAllowedToNavigateTabs(false);
        updateStatus(projectStatusConstants.SUSPENDED.key);
      })
      .catch((error) => {
        setIsChangeStatusLoading(false);
        console.log(error);
      });
  }

  function getProjectaScriptByPlatformId(platformIdentify) {
    if (PlatformsConstants.salla.platformIdentify == platformIdentify) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/project/script/${projectId}`,
          axoisConfigUtil.getHeadersWithLangAndToken()
        )
        .then((res) => {
          setScript(res.data);
          setShowScript(true);
        })
        .catch((error) => console.log(error));


      return;
    }
    if (PlatformsConstants.zid.platformIdentify == platformIdentify) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/project/script/${projectId}`,
          axoisConfigUtil.getHeadersWithLangAndToken()
        )
        .then((res) => {
          setScript(res.data);
          setShowScript(true);
        })
        .catch((error) => console.log(error));


      return;
    }
    if (PlatformsConstants.activide.platformIdentify == platformIdentify) {

      setScript(
        `
        <script>
        window.actividPublishId = '${actividPublishId}'
        let style_link = document.createElement("link");
        style_link.rel = "stylesheet";
        style_link.href = "https://base.acti-vide.com/activid-main.css";
        document.head.append(style_link);
  
        let script = document.createElement("script");
        script.src = "https://base.acti-vide.com/activid-event.js";
        script.async = true;
        script.defer = true;
        document.head.append(script);
        </script>
        `
      );
      setShowScript(true);
      return;
    }



  }

  function contactUs() {
    window.open(`https://api.whatsapp.com/send?phone=${settingsConstants.ACTIVID_SUPPORT_NUMBER}&text=%D8%A7%D9%87%D9%84%D8%A7%20,%20%D8%B9%D9%86%D8%AF%D9%8A%20%D9%85%D8%B4%D9%83%D9%84%D8%A9%20%D9%81%D9%8A%20%D9%85%D8%B4%D8%B1%D9%88%D8%B9%D9%8A`, "_blank");
  }

  function navigateToSalla() {
    window.open(settingsConstants.SALLA_URL, "_blank");
  }

  const sendEmailVerification = () => {
    setIsChangeStatusLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/email-verification`,
        {},
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then((res) => {
        setIsChangeStatusLoading(false);
        setIsDisabledBtn(true);
        toastifyFile.successNotify(res.data.message);
        localStorage.setItem("sendEmailVerificationCurrentDate", new Date(new Date().getTime() + 60 * 1000).toLocaleString());

      })
      .catch(
        (error) => {
          if (!error.response) {
            // network error
            toastifyFile.errorNotify(t("general.messages.error.connectionRefused") + " : " + errorConstants.projectDeploy.code);
          } else {
            setIsChangeStatusLoading(false);

            if (error.response.status === 429) {
              setIsDisabledBtn(true);
              toastifyFile.errorNotify(t("projectDeploy.messages.error.alreadySent"));
              localStorage.setItem("sendEmailVerificationCurrentDate", new Date(new Date().getTime() + 60 * 1000).toLocaleString());

            } else {
              toastifyFile.errorNotify(error.response.data.message);
            }
          }


        });
  };

  function checkIfUserAlreadyVerifiedEmail() {
    setVerifyEmailDisabledBtn(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/user-details`,
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then((res) => {
        if (res.data.user.email_verified_at != null) {
          toastifyFile.successNotify(t("projectDeploy.messages.success.accountActivated"));
          setUserData(res.data.user);
        } else {
          toastifyFile.warnNotify(t("projectDeploy.messages.error.accountNotActivated"));
        }

      })
      .catch((error) => console.log(error));
  }

  return (
    <>
      {(notAllowedToNavigateTabs && status.key === projectStatusConstants.DEPLOYED.key) ? (
        <Alert
          status='info'
          rounded='xl'
          margin='2rem auto'
          flexWrap='wrap'
          width='80%'
          rowGap='5px'
        >
          <AlertIcon />
          <AlertTitle>{t("projectDeploy.alertHeading")}</AlertTitle>
          <AlertDescription>{t("projectDeploy.alertText")}</AlertDescription>
        </Alert>
      ) : (
        <p className='text-primary pt-9 text-xl mx-[1.5rem] font-light'>
          {t("brief.deploy")}
        </p>
      )}
      <Stack className='m-16 mt-8'>
        <HStack>
          <Text fontSize='lg:text-xl 3xl'>
            {t("projectDeploy.projectStatus")} :{" "}
          </Text>
          <Text
            style={status.color != undefined ? { color: status.color } : null}
          >
            <FaCircle />
          </Text>
          <Text
            fontSize='lg:text-xl 3xl'
            style={status.color != undefined ? { color: status.color } : null}
          >
            {language === languageConstants.AR
              ? status.arTitle
              : status.enTitle}
          </Text>
        </HStack>
        <Stack
          fontSize='lg:text-lg 2xl'
          className='bg-[#F7F7F7] py-8 px-8 w-full'
          borderRadius='87px 87px 87px 0px'
        >
          <Text>
            {language === languageConstants.AR
              ? status.arMessage
              : status.enMessage}
          </Text>
          {showScript ? (
            <HStack>
              <Box className='rounded-full border-2 lg:text-sm text-xs border-black bg-white py-4 px-6 m-2'>
                <Code dir='ltr' className=''>
                  {script}
                </Code>
              </Box>
              <MdContentCopy
                className='cursor-pointer text-7xl w-28'
                onClick={() => {
                  navigator.clipboard.writeText(script);
                }}
              />
            </HStack>
          ) : null}
        </Stack>

        {isDisabledBtn ?
          <Countdown
            date={Date.now() + disabledSeconds}
            renderer={renderer}
            onComplete={() => {
              // reset to default

              setDisabledSeconds(59000);
              setIsDisabledBtn(false);
            }}
          />
          :
          <Button
            className='w-fit text-white'
            borderRadius='87px 87px 87px 87px'
            style={
              status.color != undefined ? { backgroundColor: status.color } : null
            }
            isLoading={isChangeStatusLoading}
            onClick={(e) => {
              doAction();
              if (
                status.key === projectStatusConstants.READY.key ||
                status.key === projectStatusConstants.SUSPENDED.key
              ) {
                party.confetti(e.target);
              }
            }}
          >
            {language === languageConstants.AR
              ? status.arButton
              : status.enButton}
          </Button>
        }
      </Stack>



      {
        status.key === projectStatusConstants.USER_NOT_VERIFIED.key ?
          <Stack className='m-16'>
            <HStack>
              <Text>
                {t("projectDeploy.notVerifiedEmail.message")}
              </Text>

              {verifyEmailDisabledBtn ?
                <Countdown
                  date={Date.now() + 10000}
                  renderer={verifyEmailRenderer}
                  onComplete={() => {
                    // reset to default
                    setVerifyEmailDisabledBtn(false);
                  }}
                />
                :
                <Button
                  className='w-fit text-white'
                  borderRadius='87px 87px 87px 87px'
                  style={
                    status.color != undefined ? { backgroundColor: status.color } : null
                  }
                  onClick={(e) => {
                    checkIfUserAlreadyVerifiedEmail();
                  }}
                >
                  {t("projectDeploy.notVerifiedEmail.action")}
                </Button>
              }
            </HStack>
          </Stack>
          : null
      }

    </>
  );
}

export default ProjectDeploy;
