import React, { useEffect, useState } from "react";

function VideoThumbnail({ videoFile }) {
  const [thumbnail, setThumbnail] = useState(null);

  useEffect(() => {
    if (!videoFile) {
      setThumbnail(null);
      return;
    }

    const videoElement = document.createElement("video");
    const canvasElement = document.createElement("canvas");
    canvasElement.width = "60";
    canvasElement.height = "100";
    const context = canvasElement.getContext("2d");

    videoElement.onloadeddata = () => {
      videoElement.currentTime = 0;
    };

    videoElement.onseeked = () => {
      context.drawImage(videoElement, 0, 0, "60", "100");
      const img = canvasElement.toDataURL();
      setThumbnail(img);
    };

    videoElement.src = URL.createObjectURL(videoFile);
  }, [videoFile]);

  if (!thumbnail) {
    return null;
  }

  return <img src={thumbnail} className='rounded' alt='Video thumbnail' />;
}

export default VideoThumbnail;
