
import { createContext, useContext, useState } from "react";

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null)

    const setUserData = (user) => {
        const userObj = {
            name: user.name,
            role: user.role,
            isVerified: user.email_verified_at != null ? true : false,
        }
        setUser(userObj)
        localStorage.setItem("user", JSON.stringify(userObj))
    }

    return (
        <UserContext.Provider value={{ user, setUserData }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUserContext = () => {
    return useContext(UserContext);
};