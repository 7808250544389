import { t } from "i18next";
import { useEffect } from "react";

function UserVideos() {
  useEffect(() => {
    document.title = `${t("title.projectName")} | ${t(
      "userDashboard.myVideos"
    )}`;
  }, []);
  return <>UserVideos</>;
}

export default UserVideos;
