import { useState } from "react";
import Switch from "react-switch";
import languageConstants from "../../../constants/LanguageConstants";
import i18n from "../../../i18n";

function CustomReactSwitch({ language, setLanguage }) {
  const [isLanguageEn, setIsLanguageEn] = useState(
    language == languageConstants.EN ? false : true
  );
  const changeLanguage = (lng) => {
    setLanguage(lng);
    i18n.changeLanguage(lng);
    document.body.dir = i18n.dir();
    document.documentElement.dir = i18n.dir();
    document.documentElement.lang = lng;
  };

  return (
    <Switch
      checked={isLanguageEn}
      onChange={() => {
        setIsLanguageEn(!isLanguageEn);
        changeLanguage(
          language === languageConstants.EN
            ? languageConstants.AR
            : languageConstants.EN
        );
      }}
      activeBoxShadow='0px 0px 0px 0px'
      offColor='#ffffff'
      onColor='#ffffff'
      offHandleColor='#00bcee'
      onHandleColor='#00bcee'
      checkedIcon={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 15,
          }}
        >
          EN
        </div>
      }
      uncheckedIcon={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 15,
          }}
        >
          ع
        </div>
      }
    />
  );
}

export default CustomReactSwitch;
