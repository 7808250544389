import i18nextUtils from './i18nextUtils'

function getHeadersWithLangAndToken() {

    let token = localStorage.getItem("token");
    if (!localStorage.getItem("i18nextLng")) {
        i18nextUtils.setBrowserLanguage();
    }
    return { headers: { "Accept-Language": localStorage.getItem("i18nextLng"), Authorization: `Bearer ${token}` } }

}

function getHeadersWithLang() {

    if (!localStorage.getItem("i18nextLng")) {
        i18nextUtils.setBrowserLanguage();
    }
    return { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
}



const axoisConfigUtil = {
    getHeadersWithLangAndToken,
    getHeadersWithLang
};

export default axoisConfigUtil;
