import NoMatchComponent from "./NoMatchComponent";

function DashboardNoMatch() {
  return (
    <div className='flex flex-col justify-center items-center'>
      <NoMatchComponent pageLink='/dashboard' />
    </div>
  );
}

export default DashboardNoMatch;
