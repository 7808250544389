import { Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import CustomReactSwitch from "../external-libraries/react-switch/CustomReactSwitch";

export default function AuthHeader({ pageType, language, setLanguage }) {
  const { t } = useTranslation();

  return (
    <div className='bg-[#d7eefa] w-full h-24 md:h-32 flex justify-center items-center rounded-bl-full'>
      {pageType !== "newPass" ? (
        <Flex
          w='15rem'
          justifyContent='space-around'
          ps='1.25rem'
          fontSize='1.125rem'
        >
          {pageType === "login" ? (
            <Link to='/signup'>{t("home.signUp")}</Link>
          ) : pageType === "signup" ? (
            <Link to='/login'>{t("login.logIn")}</Link>
          ) : pageType === "reset" ? (
            <>
              <Link to='/signup'>{t("home.signUp")}</Link>
              <Link to='/login'>{t("login.logIn")}</Link>
            </>
          ) : null}
        </Flex>
      ) : null}

      <img src={logo} alt='logo' className='w-1/4 h-24' />
      <div className='m-2'>
        <CustomReactSwitch
          language={language}
          setLanguage={setLanguage}
        ></CustomReactSwitch>
      </div>
    </div>
  );
}
