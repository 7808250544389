import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import nomatchGif from "../../assets/images/nomatch.gif";

function NoMatchComponent({ pageLink }) {
  const navigate = useNavigate();

  return (
    <>
      <h1 className='text-3xl'>{t("noMatchPage.header")}</h1>

      <img src={nomatchGif} alt='404 error' className='w-1/3' />

      <button
        onClick={() => navigate(pageLink)}
        className='border border-transparent px-10 py-2 font-light rounded-[33px] text-white bg-[#00BCEE] hover:bg-[#00BCEE] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00BCEE]'
      >
        {t("noMatchPage.button")}
      </button>
    </>
  );
}

export default NoMatchComponent;
