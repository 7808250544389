import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Text,
  Center,
  Button,
  Input,
} from "@chakra-ui/react";
import axios from "axios";
import { t } from "i18next";
import { useState } from "react";
import axoisConfigUtil from "../../../../utils/AxoisConfigUtil";

export default function CopyModal({
  isOpen,
  onClose,
  setOptions,
  option,
  enabledTypes,
}) {
  const [text, setText] = useState(option.copy || "");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = () => {
    setIsSubmitting(true);
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/options/${option.id}`,
        {
          copy: text,
          link: null,
          next: null,
          option_category_id: enabledTypes.find((type) => type.name === "COPY")
            .id,
        },
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then(function (ـ) {
        setOptions((prev) =>
          prev.map((opt) => {
            if (opt.id === option.id) {
              opt.link = text;
              opt.name = "COPY";
              opt.link = null;
              opt.next = null;
            }
            return opt;
          })
        );
        onClose();
        setIsSubmitting(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsSubmitting(false);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={!isSubmitting}
      size='xl'
      isCentered
    >
      <ModalOverlay />
      <ModalContent rounded='0' roundedBottom='50px' roundedTopLeft='50px'>
        <ModalCloseButton
          bg='#00bcee'
          color='white'
          h='25px'
          w='25px'
          rounded='full'
          disabled={isSubmitting}
        />
        <Center padding='10' maxW='xl' display='flex' flexDir='column'>
          <Text
            color='#00bcee'
            fontSize='3xl'
            fontWeight='light'
            alignSelf='start'
          >
            {t("building.copyableText")}
          </Text>
          <Input
            onChange={(e) => setText(e.target.value)}
            value={text}
            rounded='50px'
            borderColor='blackAlpha.500'
            my='4'
          />
          <Button
            onClick={onSubmit}
            borderRadius='36px'
            borderColor='blackAlpha.500'
            borderWidth='thin'
            color='white'
            bg='#00bcee'
            px='20'
            fontWeight='light'
            fontSize='3xl'
            py='22px'
            marginTop='5'
            isLoading={isSubmitting}
            _hover={{ backgroundColor: "#00bcee" }}
          >
            {t("building.submit")}
          </Button>
        </Center>
      </ModalContent>
    </Modal>
  );
}
