import {
  Button,
  Center,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import axoisConfigUtil from "../../../utils/AxoisConfigUtil";
import { useNavigate } from "react-router-dom";

function ProjectSettings({ t, projectId, projectName, updateProjectName }) {
  let navigate = useNavigate();

  const [deleteProjectLoading, setDeleteProjectLoading] = useState(false);

  const [name, setName] = useState(
    projectName == null || projectName == undefined ? "" : projectName
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  function updateCaption() {
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/projects/${projectId}`,
        { name: name },
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then((res) => {
        updateProjectName(name);
      })
      .catch((error) => console.log(error));
  }

  function HandleDelete() {
    setDeleteProjectLoading(true);

    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/projects/${projectId}`,
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then((res) => {
        setDeleteProjectLoading(false);
        navigate("/dashboard");
      })
      .catch((error) => {
        setDeleteProjectLoading(false);
        console.log(error);
      });
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size='lg'>
        <ModalOverlay />
        <ModalContent
          border='0.5px solid #707070'
          borderRadius='87px 0px 87px 87px'
        >
          <ModalCloseButton
            isDisabled={deleteProjectLoading}
            bg='#00bcee'
            color='white'
            h='25px'
            w='25px'
            rounded='full'
          />
          <ModalBody my='50px' color='#00bcee'>
            <Center>
              <Text align='center' fontSize='2xl' px='12' fontWeight='light'>
                {t("general.messages.confirm.deleteProject")}
              </Text>
            </Center>
            <Center mt='6'>
              <Button
                isLoading={deleteProjectLoading}
                onClick={() => {
                  HandleDelete();
                }}
                background='#00bcee'
                color='white'
                fontWeight='light'
                variant='outline'
                _hover={{
                  background: "#00bcee",
                }}
                width='50%'
                marginTop='2'
                fontSize='xl'
                rounded='3xl'
                border='0.5px #707070 solid'
              >
                {t("general.delete")}
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
      <p className='text-primary text-xl py-4 mt-5 px-[1.5rem] font-light'>
        {t("brief.settings")}
      </p>
      <div className='flex flex-col gap-2 m-6'>
        <div className='flex gap-4 items-center p-4'>
          <div>
            <div className='font-medium'>
              {t("projectSettings.projectName")}
            </div>
          </div>
          <div className=''>
            <div className='flex w-full'>
              <Input
                value={name}
                maxLength={25}
                rounded='15px'
                placeholder={t("projectSettings.projectNameCaptionPlaceholder")}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                onBlur={() => {
                  updateCaption();
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <button
            disabled
            className={` py-1 px-4 text-lg font-light rounded-full text-white mx-[1rem] bg-[#ff434d] disabled:opacity-70 disabled:cursor-not-allowed`}
            // onClick={() => {
            //   onOpen();
            // }}
          >
            <div>{t("projectSettings.deleteProject")}</div>
          </button>
        </div>
      </div>
    </>
  );
}

export default ProjectSettings;
