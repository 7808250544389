import {
  Box,
  HStack,
  Stack,
  Text,
  Image
} from "@chakra-ui/react";

import totalOfVisitors from '../../../assets/images/dashboard/ProjectStatistics/Total of visitors.png'
import openWidget from '../../../assets/images/dashboard/ProjectStatistics/Open Widget.png'
import closeWidget from '../../../assets/images/dashboard/ProjectStatistics/closeWidget.png'
import closeModal from '../../../assets/images/dashboard/ProjectStatistics/closeModal.png'

import { useEffect, useState } from "react";
import axios from "axios";
import axoisConfigUtil from "../../../utils/AxoisConfigUtil";

function ProjectStatistics({
  t,
  language,
  projectId,
}) {

  const [projectStatistics, setProjectStatistics] = useState({ WIDGET_OPEN: 0, WIDGET_CLOSE: 0, MODAL_OPEN: 0, MODAL_CLOSE: 0 });


  useEffect(() => {
    getProjectStatistics();
  }, []);



  function getProjectStatistics() {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/statistics/${projectId}`,
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then((res) => {
        setProjectStatistics(res.data);

      })
      .catch((error) => console.log(error));
  }


  return (
    <>
      <Stack className='m-16 mt-20'>
        <HStack justifyContent={'center'}>

          <HStack bg='#fff8fa' alignContent={'center'} borderRadius='md' p={4} h={'fit-content'}>
            <Box>
              <Image boxSize='50px' src={totalOfVisitors} />
            </Box>
            <Text as='b'>
              Total of visitors
              <Text textAlign={'center'} color='#df0a56' fontSize='xl'>{projectStatistics.WIDGET_OPEN}</Text>
            </Text>
          </HStack>

          <HStack bg='#e6fff8' alignContent={'center'} borderRadius='md' p={4} h={'fit-content'}>
            <Box>
              <Image boxSize='50px' src={openWidget} />
            </Box>
            <Text as='b'>
              OPEN ( Widget )
              <Text textAlign={'center'} color='#26a26b' fontSize='xl'>{projectStatistics.MODAL_OPEN}</Text>
            </Text>
          </HStack>

          <HStack bg='#fbebff' alignContent={'center'} borderRadius='md' p={4} h={'fit-content'}>
            <Box>
              <Image boxSize='50px' src={closeWidget} />
            </Box>
            <Text as='b'>
              CLOSE ( Widget )
              <Text textAlign={'center'} color='#9508c9' fontSize='xl'>{projectStatistics.WIDGET_CLOSE}</Text>
            </Text>
          </HStack>

          <HStack bg='#fff7f2' alignContent={'center'} borderRadius='md' p={4} h={'fit-content'}>
            <Box>
              <Image boxSize='50px' src={closeModal} />
            </Box>
            <Text as='b'>
              CLOSE ( Modal )
              <Text textAlign={'center'} color='#d36216' fontSize='xl'>{projectStatistics.MODAL_CLOSE}</Text>
            </Text>
          </HStack>

        </HStack>
      </Stack>
    </>
  );
}

export default ProjectStatistics;
