import NoMatchComponent from "./NoMatchComponent";

function NoMatch() {
  return (
    <div className='h-screen flex flex-col justify-center items-center'>
      <NoMatchComponent pageLink='/' />
    </div>
  );
}

export default NoMatch;
