import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
} from "@chakra-ui/react";
import {
  IoChevronDown,
  IoChevronUp,
  IoTrashOutline,
} from "react-icons/io5";
import { IoIosSend } from "react-icons/io";
import { AiOutlineEdit } from "react-icons/ai";
import { FcCancel } from "react-icons/fc";
import { useState } from "react";
import axios from "axios";
import axoisConfigUtil from "../../../utils/AxoisConfigUtil";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Input } from "@chakra-ui/input";
import { HStack } from "@chakra-ui/react";


export default function RulesItem({
  rule,
  t,
  rulesList,
  setRulesList,
  inProgressToAddRule,
  setInProgressToAddRule,
  updateProjectRules
}) {
  const [ruleValue, setRuleValue] = useState(rule.value || "");
  const [edit, setEdit] = useState(false);
  const [addRuleLoading, setAddRuleLoading] = useState(false);
  const [deleteRuleLoading, setDeleteRuleLoading] = useState(false);
  const [updateRuleLoading, setUpdateRuleLoading] = useState(false);
  let ruleInitialValue = rule.value || "";

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      website: ruleValue,
    },
    validationSchema: Yup.object({
      website: Yup.string()
        .required(t("formik.required"))
        .url(t("formik.validURL"))
    }),
    onSubmit: (values) => {
    },
  });

  const handleRemoveCancelledRule = () => {
    setRulesList((prev) => prev.filter((o) => o.id != undefined));
    setInProgressToAddRule(false);
  };

  const handleAddNewRule = (rule) => {
    setAddRuleLoading(true);

    rule.value = ruleValue;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/project-rules`,
        rule,
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then((res) => {
        setAddRuleLoading(false);

        let newRule = res.data
        // TODO : enhance coding @saad
        const temp = [];
        rulesList.map((ele) => {
          if (ele.id != undefined) {
            temp.push(ele);
          } else if (rule.id === ele.id) {
            ele.value = ruleValue;
          }
        });

        temp.push(newRule);
        setRulesList(temp);
        finalUpdatedRules(temp);

        setInProgressToAddRule(false);
      })
      .catch((error) => {
        console.log(error)

        setAddRuleLoading(false);
        formik.setErrors({ website: error.response.data.message });
      });
  };

  const handleUpdateRole = (rule) => {
    setUpdateRuleLoading(true);

    rule.value = ruleValue;

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/project-rules/${rule.id}`,
        { value: rule.value },
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then((res) => {

        // TODO : enhance coding @saad
        const temp = [];
        rulesList.map((ele) => {
          if (ele.id == rule.id) {
            let updateRule = ele;
            updateRule.value = rule.value;
            temp.push(updateRule);
          } else {
            temp.push(ele);
          }

        });
        setRulesList(temp);
        finalUpdatedRules(temp);

        setUpdateRuleLoading(false);
        setEdit(false);
      })
      .catch((error) => console.log(error));
  };

  const handleDeleteRule = (id) => {
    setDeleteRuleLoading(true);

    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/project-rules/${id}`,
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then((res) => {
        setDeleteRuleLoading(false);

        // TODO : enhance coding @saad
        const temp = [];
        rulesList.map((ele) => {
          if (ele.id != id) {
            temp.push(ele);
          }
        });

        setRulesList(temp);
        finalUpdatedRules(temp);

      })
      .catch((error) => console.log(error));
  };

  function finalUpdatedRules(updatedRuls) {
    updateProjectRules(updatedRuls);
  }

  return (
    <div className='w-full flex gap-1 mt-6 items-center border'>
      <Menu matchWidth>
        {({ isOpen }) => (
          <>
            <MenuButton
              padding='1px 10px'
              rounded='15px'
              as={Button}
              bg='#323232'
              color='white'
              _hover={{
                color: "white",
                bg: "#323232",
              }}
              _expanded={{
                color: "white",
                bg: "#323232",
              }}
              w='120px'
              fontWeight='light'
              rightIcon={isOpen ? <IoChevronUp /> : <IoChevronDown />}
            >
              {t("projectRules.equalsTo")}
            </MenuButton>
            <MenuList rounded='15px'>
              <MenuItem>{t("projectRules.equalsTo")}</MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
      <div className="w-3/4 m-2">

        <Input
          type='text'
          name='website'
          required
          isInvalid={formik.errors.website && formik.touched.website}
          errorBorderColor='red.300'
          onBlur={formik.handleBlur}
          defaultValue={ruleValue}
          disabled={
            (inProgressToAddRule && rule.id != undefined) ||
            (!edit && rule.id != undefined)
          }
          onChange={(e) => {
            formik.handleChange(e);
            setRuleValue(e.target.value);
          }}
          className='border border-gray-300 w-24 rounded-xl py-1 px-2 placeholder:text-base flex-1'
        />
        <div>
          {
            formik.errors.website && formik.touched.website && (<div className='text-red-400'>* {formik.errors.website}</div>)
          }
        </div>
      </div>


      {
        inProgressToAddRule && rule.id == undefined ? (
          <>
            {
              !addRuleLoading ?
                <Button
                  background='#FFFF' variant='#FFFF'
                  rounded={"full"}
                  onClick={() => handleRemoveCancelledRule()}>
                  <FcCancel className='text-3xl' />
                </Button>
                : null
            }

            {
              ruleValue.length > 1 ?
                <Button
                  background='#FFFF' variant='#FFFF'
                  rounded={"full"}
                  isLoading={addRuleLoading}
                  isDisabled={!formik.isValid}
                  onClick={() => handleAddNewRule(rule)}>
                  <IoIosSend className='text-primary text-3xl' />
                </Button>
                : null
            }
          </>
        ) : null
      }

      {
        !inProgressToAddRule && rule.id != undefined ? (
          <>
            {edit ? (
              <HStack spacing='24px'>
                {
                  !updateRuleLoading ?
                    <Button
                      background='#FFFF' variant='#FFFF'
                      rounded={"full"}
                      onClick={() => { setEdit(false); }}
                    >
                      <FcCancel className='text-3xl' />
                    </Button>
                    : null
                }
                <Button
                  background='#FFFF' variant='#FFFF'
                  rounded={"full"}
                  isLoading={updateRuleLoading}
                  isDisabled={!formik.isValid}
                  onClick={() => handleUpdateRole(rule)}>
                  <IoIosSend className='text-primary text-3xl' />
                </Button>
              </HStack>
            ) : (
              !deleteRuleLoading ?
                <Button
                  background='#FFFF' variant='#FFFF'
                  rounded={"full"}
                  onClick={() => { setEdit(true); }}
                >
                  <AiOutlineEdit className='text-primary text-3xl ' />
                </Button>
                : null
            )}

            {
              !edit ?
                <Button
                  background='#FFFF' variant='#FFFF' rounded={"full"}
                  isLoading={deleteRuleLoading}
                  onClick={() => handleDeleteRule(rule.id)}
                >
                  <IoTrashOutline className='text-primary text-3xl' />
                </Button>
                : null
            }
          </>
        ) : null
      }
    </div>
  );
}
