import { Outlet } from "react-router-dom";

function HomeLayout({ language, setLanguage }) {
  return (
    <>
      {/* <HomeNavBar language={language} setLanguage={setLanguage} /> */}
      <div>
        <Outlet />
      </div>
      {/* <HomeFooter /> */}
    </>
  );
}

export default HomeLayout;
