import { Navigate, Outlet } from "react-router-dom";
import toastifyFile from "../../external-libraries/React-toastify/index";
import RoleConstants from "../../../constants/RoleConstants";
import errorConstants from "../../../constants/ErrorConstants";
import { useTranslation } from "react-i18next";

function AuthAdminDashboard() {
  const { t } = useTranslation();

  function hasTokenAndAuthz() {
    const hasToken = localStorage.getItem("token") != null;
    const hasUser = localStorage.getItem("user") != null;

    if (hasToken && hasUser) {
      if (
        JSON.parse(localStorage.getItem("user")).role ===
        RoleConstants.ADMIN_ROLE
      ) {
        return <Outlet />;
      } else {
        toastifyFile.errorNotify(
          t("general.messages.error.userNotAuthorized") +
            " : " +
            errorConstants.authAdminDashboard.code
        );
        return <Navigate to={"/"} />;
      }
    } else {
      toastifyFile.errorNotify(
        t("general.messages.error.loginFirst") +
          " : " +
          errorConstants.authAdminDashboard.code
      );
      return <Navigate to={"/login"} />;
    }
  }

  return hasTokenAndAuthz();
}

export default AuthAdminDashboard;
