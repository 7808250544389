export const data = {
  ar: ` <p>
    أهلاً بكم في موقع اكتفيد، يُرجى قراءة شروط الاستخدام التالية بعناية
    قبل استخدام هذا الموقع حتى تكون على علم بحقوقك القانونية والالتزامات
    المترتبة على شركة اكتفيد .
    <br />
    من خلال الوصول إلى الموقع أو استخدامه، فإنك تقر بشكل صريح وتوافق على
    أنك تدخل في اتفاقية قانونية معنا وقد فهمت ووافقت على الامتثال لشروط
    الاستخدام هذه، جنباً إلى جنب مع سياسة الخصوصية ("الشروط").
    <br />
    تكون هذه الشروط بين اكتفيد وبينك، سواءً على سبيل المثال لا الحصر، أو
    نيابة عن صاحب العمل أو أي كيان آخر تمثله ("أنت" أو "العميل"). في
    حالة تمثيلك لصاحب العمل أو كيان آخر، فإنك تمثل هنا أن
    <br /> (1) لديك السلطة القانونية الكاملة لربط صاحب عملك أو هذا
    الكيان (حسب الاقتضاء) بشروط الاستخدام هذه؛ و
    <br />
    (2) بعد قراءة وفهم هذه الشروط، فإنك توافق على هذه الشروط نيابة عن
    صاحب العمل أو الكيان المعني (حسب الاقتضاء)، وتكون هذه الشروط ملزمة
    لصاحب العمل أو الكيان المعني (حسب الحالة). تتنازل هنا عن أي حقوق
    قابلة للتطبيق لتتطلب توقيعًا أصليًا (غير إلكتروني) أو تسليم أو
    الاحتفاظ بسجلات غير إلكترونية، إلى حد عدم حظر ذلك بموجب القانون
    المعمول به.
    <br />
    إذا كنت لا توافق على الالتزام بهذه الشروط، يُرجى عدم الدخول إلى
    الموقع أو استخدامه
  </p>
  <ul>
    <li>
      <span className='font-bold'>التعديل: </span>
      نحتفظ بالحق، حسب تقديرنا، في تغيير هذه الشروط في أي وقت. ستصبح هذه
      التغييرات سارية المفعول بعد عشرة (10) أيام من نشر الشروط المعدلة
      على الموقع، واستخدامك المتواصل للموقع بعد ذلك يعني قبولك لهذه
      التغييرات.
    </li>
    <li>
      <span className='font-bold'>رسوم الاشتراك: </span>كنظير للترخيص
      الممنوح بموجبه، يجب على العميل دفع رسوم اكتفيد المحددة في الطلب
      المعمول به ("الرسوم"). "الطلب" يعني استمارة اكتفيد الإلكترونية
      التي يكملها العميل، والتي تسرد الخدمات المطلوبة، وخطة الاشتراك،
      والمدة والرسوم المرتبطة بها. سيتم تجديد الطلب تلقائيًا لنفس فترة
      الاشتراك ما لم ينه اكتفيد أو أنت الاشتراك قبل انتهاء فترة الاشتراك
      الحالية. تحتفظ اكتفيد بالحق في تغيير رسومها في أي وقت، ويتعين
      إبلاغ العميل بتلك التغييرات عبر البريد الإلكتروني قبلها. إذا لم
      يوافق العميل على تغيير الأسعار، فإن الحل الوحيد للعميل هو إنهاء
      الطلب المعمول به، والذي يمكن أن يفعله داخل المنصة. في حالة زيادة
      اكتفيد للرسوم وإنهاء العميل للطلب المعمول به وفقًا للجملة السابقة
      بدون استرجاع أي مبلغ. يوافق العميل على تزويد اكتفيد بمعلومات
      الفوترة المحدثة والدقيقة والكاملة، ويفوض العميل اكتفيد سواء
      مباشرةً أو من خلال شركات التابعة أو الشركات الأخرى بتحصيل الدفع أو
      تحميل غير ذلك أو استرداد أو اتخاذ أي إجراءات فوترة أخرى من طريقة
      الدفع الخاصة بالعميل، وإجراء أي استفسارات قد نعتبرها ضرورية للتحقق
      من صحة حساب الدفع المعين للعميل أو المعلومات المالية، من أجل ضمان
      الدفع السريع، بما في ذلك لغرض استلام تفاصيل الدفع المحدثة من شركة
      بطاقة ائتمان العميل أو الحساب المصرفي (مثل تاريخ الانتهاء المحدث
      أو رقم البطاقة الذي قد يتم توفيره لنا من قبل شركة بطاقة ائتمان
      العميل). ما لم يُنص على خلاف ذلك هنا أو في الطلب:
      <br />
      <ul>
        <li>تكون الرسوم مقدرة بالدولار الأمريكي</li>
        <li>
          لا يُرد جميع الرسوم والمبالغ الأخرى المدفوعة بموجب هذا. تكون
          جميع المبالغ المستحقة بموجب هذه الشروط حصرية لجميع المبيعات
          والاستخدام والضريبة على القيمة المضافة والاحتفاظ بالضرائب
          والرسوم والتكاليف الأخرى المباشرة أو غير المباشرة.
        </li>
      </ul>
    </li>
    <li>
      <span className='font-bold'>الحساب: </span>
      لاستخدام المنصة، قد يكون على العميل إنشاء حساب أو استخدام حساب
      موجود (المعروف بـ "الحساب"). يجب على العميل توفير معلومات حساب
      دقيقة وكاملة ويكون المسؤول الوحيد عن النشاط الذي يحدث في حسابه.
      يوافق العميل هنا على الحفاظ على سرية كلمة مرور حسابه وإبلاغ اكتفيد
      على الفور عن أي استخدام غير مصرح به لحسابه.
    </li>
    <li>
      <span className='font-bold'>المستخدمون المسموح لهم: </span>
      يمكن الوصول إلى المنصة فقط من قبل موظفي العميل أو مقدمي الخدمة
      الذين يأذن لهم صراحة بذلك من قبل العميل لاستخدام المنصة (كل منهم
      "مستخدم مسموح له"). سيضمن العميل أن يلتزم المستخدمون المسموح لهم
      بشروط هذه الاتفاقية في جميع الأوقات؛ ويكون مسؤولاً بشكل كامل عن أي
      انتهاك لهذه الاتفاقية من قبل مستخدم مسموح له. يجب الإبلاغ عن
      الوصول أو الاستخدام غير المصرح به للمنصة فورًا إلى اكتفيد .
    </li>
    <li>
      <span className='font-bold'>رسوم الاشتراك: </span>
      بالنظر إلى الترخيص الممنوح بموجب هذا العقد، يجب على العميل دفع
      الرسوم المنصوص عليها في الطلب المتعلق ("الرسوم") لـ اكتفيد .
      "الطلب" يعني النموذج الإلكتروني الخاص بـ اكتفيد الذي يكمله العميل،
      والذي يتضمن قائمة بالخدمات المطلوبة وخطة الاشتراك والمدة والرسوم
      المرتبطة بها. سيتم تجديد الطلب تلقائيًا لنفس فترة الاشتراك ما لم
      ينهي أي من طرفي العقد الاشتراك قبل انتهاء فترة الاشتراك الحالية.
      تحتفظ اكتفيد بالحق في تغيير رسومها في أي وقت، وسيتم إبلاغ العميل
      بهذه التغييرات عبر البريد الإلكتروني قبل تاريخ سريان هذه التغييرات
      ("الإشعار"). في حالة عدم موافقة العميل على تغيير الأسعار، فإن الحل
      الوحيد للعميل هو إنهاء الطلب المعني، ويمكنه القيام بذلك من خلال
      المنصة. في حال زيادة اكتفيد للرسوم وإنهاء العميل للطلب المعني
      وفقًا للجملة السابقة، ستقوم اكتفيد بإعادة مبلغ مالي متناسب للجزء
      غير المستخدم من الرسوم المدفوعة مسبقًا من قبل العميل للفترة
      المتبقية من مدة الاشتراك. يوافق العميل على تزويد اكتفيد بمعلومات
      الفوترة المحدثة والدقيقة والكاملة، ويأذن لـ اكتفيد (سواء مباشرة أو
      عن طريق الشركات التابعة أو الشركات الأخرى) بتحصيل الدفع (أو غير
      ذلك من الشحن أو الاسترداد أو اتخاذ أي إجراءات فوترة أخرى) من طريقة
      الدفع الخاصة بالعميل، وإجراء أي استفسارات قد نعتبرها ضرورية للتحقق
      من صحة حساب الدفع المعين للعميل أو المعلومات المالية.
    </li>
    <li>
      <span className='font-bold'>القيود: </span>
      باستثناء ما يسمح به صراحة في هذا الاتفاق، يجب عدم السماح للعميل أو
      أي مستخدم مسموح به أو أي طرف ثالث بشكل مباشر أو غير مباشر: (1)
      بنسخ أو تعديل أو إنشاء أعمال مشتقة من أي جزء من الموقع (بما في ذلك
      الاستثمار في منتجاتها)؛ (2) بيع أو ترخيص (أو إعادة الترخيص) أو
      تأجير أو تحويل أو رهن أو مشاركة حقوق العميل في هذه الشروط مع أي
      طرف ثالث؛ (3) استخدام أي برامج "مفتوحة المصدر" أو "copyleft"
      بطريقة تتطلب من أكتفيد كشف شفرة المصدر للموقع
    </li>
    <li>
      <span className='font-bold'>حقوق الملكية الفكرية: </span>
      <ul>
        <li>
          <span className='font-bold'>المحتوى والعلامات: </span>
          (1) المحتوى على الموقع، بما في ذلك على سبيل المثال لا الحصر
          النصوص والوثائق والمقالات والكتيبات والمنتجات والبرامج
          والرسومات والصور والأصوات ومقاطع الفيديو والميزات التفاعلية
          والخدمات (المجموعة بشكل عام باسم "المواد")، و (2) العلامات
          التجارية والعلامات التجارية والشعارات الواردة فيها ("العلامات"
          وجنبًا إلى جنب مع "المواد"، "المحتوى") هي ملكية اكتفيد و / أو
          مرخصيها ويمكن أن تحمى بموجب قوانين حقوق المؤلف أو الملكية
          الفكرية الأخرى والمعاهدات. "اكتفيد"، "شعار اكتفيد" وعلامات
          أخرى هي علامات تجارية لاكتفيد أو شركاتها التابعة. جميع
          العلامات التجارية الأخرى وعلامات الخدمة والشعارات المستخدمة
          على الموقع هي علامات تجارية أو علامات تجارية أو شعار تابع
          لأصحابها الشرعيين. نحن نحتفظ بجميع الحقوق التي لم يتم منحها
          صراحة في الموقع والمحتوى.
        </li>
        <li>
          <span className='font-bold'>استخدام المحتوى: </span>
          يتم توفير محتوى على الموقع للمستخدم للمعلومات والاستخدام
          الشخصي فقط، ولا يجوز استخدامه أو تعديله أو نسخه أو توزيعه أو
          نقله أو بيعه أو إعادة ترخيصه أو إزالة التشفير منه أو استغلاله
          بأي طريقة أخرى لأي أغراض أخرى على الإطلاق دون موافقتنا الخطية
          المسبقة. إذا قمت بتنزيل أو طباعة نسخة من المحتوى، فيجب أن
          تحتفظ بجميع إشعارات حقوق المؤلف والملكية الأخرى الموجودة فيه,
          بما في ذلك أي حقوق الملكية الفكرية المثبتة في المنصة و / أو
          المتجسدة فيها و / أو المرتبطة بها (وأي تحسينات وأعمال مشتقة من
          ذلك) وأي منتجات أو تسليمات أو خدمات أخرى تقدمها اكتفيد، هي
          وتظل ملكًا لاكتفيد أو مرخصيها بالكامل. لا تنقل هذه الشروط
          للعميل أي مصلحة في المنصة أخرى من حق استخدام المنصة بموجب
          الأمر المناسب. لا شيء هنا يشكل تنازلًا عن حقوق الملكية الفكرية
          لاكتفيد بموجب أي قانون.
        </li>
        <li>
          <span className='font-bold'>التحليلات: </span>
          يمكن استخدام أي معلومات مجهولة المصدر والتي تم استمدادها من
          استخدام الموقع (أي الميتاداتا أو المعلومات المجمعة و / أو
          التحليلات) والتي لا تحتوي على معلومات شخصية محددة ("معلومات
          التحليلات") من قبل اكتفيد فيما يتعلق بتقديم الخدمات بموجب هذه
          الشروط، و لأغراض التطوير و / أو الإحصائيات. تعتبر هذه
          المعلومات حصرية لاكتفيد.
        </li>
      </ul>
    </li>
    <li>
      <span className='font-bold'>الإفراط في الأداء: </span>
      في حالة وجود أي مشكلة تتعلق بخدمات اكتفيد، فإن المسؤولية الكاملة
      لأي خسارة أو ضرر يتحملها العميل تقتصر على مبلغ الرسوم التي دفعها
      العميل لاكتفيد بموجب هذا الأمر الذي ينتج عنه المطالبة.
      <ul>
        <li>
          <span className='font-bold'>المسؤولية المحتملة: </span>
          في حالة وجود أي مشكلة تتعلق بخدمات اكتفيد، فإن المسؤولية
          الكاملة لأي خسارة أو ضرر يتحملها العميل تقتصر على مبلغ الرسوم
          التي دفعها العميل لاكتفيد بموجب هذا الأمر الذي ينتج عنه
          المطالبة.
        </li>
        <li>
          <span className='font-bold'>تعويض: </span>
          يتعهد العميل بتعويض اكتفيد وموظفيها وعملائها ومورديها
          ومسؤولياتها ومدرائها ووكلائها والشركات التابعة لها وشركائها
          والمستخدمين الآخرين للموقع والخدمات من أي خسارة أو ضرر أو
          تكلفة أو مصروف (بما في ذلك رسوم المحاماة المعقولة) ، بما في
          ذلك بدون حصر، بسبب (أ) استخدامه للخدمات، و (ب) انتهاكه لأي
          شروط من شروط هذه الاتفاقية.
        </li>
      </ul>
    </li>
    <li>
      <span className='font-bold'>
        المصادر والمحتوى الخاص بالأطراف الثالثة:{" "}
      </span>
      يتيح لك الموقع عرض والوصول إلى والربط بمحتوى الأطراف الثالثة
      (المعرفة أدناه) الذي لا يملكه أو يتحكم به "اكتفيد" ("محتوى الطرف
      الثالث"). قد يتيح لك الموقع أيضًا التواصل والتفاعل مع مصادر الطرف
      الثالث. "مصادر الطرف الثالث" تعني: (1) مواقع وخدمات الأطراف
      الثالثة؛ و(2) شركاء وعملاء "اكتفيد".
    </li>
    <li>
      <span className='font-bold'>
        ليس لدينا انتماء أو تحكم على أي من مصادر الطرف الثالث:{" "}
      </span>
      نحن لا نتحمل أي مسئولية عن محتوى الطرف الثالث أو شروط الاستخدام أو
      سياسات الخصوصية أو تصرفات أو ممارسات أي مصادر الطرف الثالث. يُرجى
      قراءة شروط الاستخدام وسياسة الخصوصية الخاصة بأي مصدر طرف ثالث
      تتفاعل معه قبل الانخراط في أي نشاط من هذا القبيل.
    </li>
    <li>
      لسنا مسؤولين عن، ونحن صراحة نخلي المسؤولية عن، دقة، أو ملاءمة، أو
      فائدة، أو سلامة، أو حقوق الملكية الفكرية (المعرفة أدناه) لأي محتوى
      خاص بطرف ثالث، أو المتعلق به.
    </li>
    <li>
      لا نؤيد أي إعلانات أو ترويج أو حملات أو منتجات أو خدمات أو مواد
      أخرى مدرجة في أي محتوى خاص بطرف ثالث أو يتم التواصل معك من مصدر
      طرف ثالث.في التفاعل مع مصدر طرف ثالث أو عرض واستخدام محتوى الطرف
      الثالث. يعتبر تفاعلك مع مصدر طرف ثالث واستخدامك للمحتوى الخاص
      بالطرف الثالث والاعتماد عليه على مسؤوليتك الخاصة ومن مخاطرتك
      الخاصة.
    </li>
    <li>
      أنت المسؤول الوحيد والملزم بتفاعلك مع مصدر طرف ثالث. توافق على
      التنازل عن وتتنازل بهذا الوثيقة عن أي حقوق أو تصحيحات قانونية أو
      عادلة قد تكون لديك ضد "اكتفيد" وتحرير "اكتفيد" من أي وكل
      المسؤولية، الناشئة عن استخدامك للمحتوى الخاص بطرف ثالث وتفاعلك مع
      أي مصدر طرف ثالث. إذا كان لديك أي استفسار أو شكوى بخصوص مصدر طرف
      ثالث أو أي محتوى خاص بطرف ثالث، فإنك توافق على الاتصال بمصدر الطرف
      الثالث مباشرة.
    </li>
    <li>
      <span className='font-bold'>وصف المعلومات: </span>
      نحاول أن نكون دقيقين قدر الإمكان. ومع ذلك، لا يمكننا ولا نضمن أن
      المحتوى المتوفر على الموقع دقيق وكامل وموثوق وحديث وخالٍ من
      الأخطاء. نحتفظ بالحق في إجراء تغييرات في المحتوى أو أي جزء منه،
      وفقًا لتقديرنا الخاص وبدون مطالبة بإشعار قبل أو بعد إجراء هذه
      التغييرات على المحتوى. تتم استخدامك للمحتوى أو أي جزء منه على
      مسؤوليتك الخاصة وخطرك الخاص.
    </li>
    <li>
      <span className='font-bold'>الإفصاح: </span>
      نحتفظ بالحق في الوصول إلى المعلومات وقراءتها والاحتفاظ بها والكشف
      عنها، التي نحصل عليها فيما يتعلق بالموقع واستخدامك له، حسبما نعتقد
      أنه من الضروري بشكل معقول لـ (1) الامتثال لأي قانون أو لوائح أو
      إجراءات قانونية أو استدعاء حكومي، (2) تنفيذ شروط الموقع، بما في
      ذلك التحقيق في انتهاكات محتملة لها، (3) كشف أو منع أو التعامل
      بطريقة أخرى مع التزوير والقضايا الأمنية أو التقنية، (4) الرد على
      طلبات دعم المستخدمين، أو (5) حماية حقوق وممتلكات وسلامة اكتفيد
      ومستخدميه
    </li>
    <li>
      <span className='font-bold'>الخصوصية: </span>
      سنستخدم أي معلومات شخصية قد نجمعها أو نحصل عليها فيما يتعلق
      بالموقع وفقًا لسياسة الخصوصية الخاصة بنا .
    </li>
    <li>
      <span className='font-bold'>
        حقوق الطبع والنشر وسياسة المحتوى:{" "}
      </span>
      نحتفظ بالحق في الوصول إلى المعلومات وقراءتها والاحتفاظ بها والكشف
      عنها، التي نحصل عليها فيما يتعلق بالموقع واستخدامك له، حسبما نعتقد
      أنه من الضروري بشكل معقول لـ (1) الامتثال لأي قانون أو لوائح أو
      إجراءات قانونية أو استدعاء حكومي، (2) تنفيذ شروط الموقع، بما في
      ذلك التحقيق في انتهاكات محتملة لها، (3) كشف أو منع أو التعامل
      بطريقة أخرى مع التزوير والقضايا الأمنية أو التقنية، (4) الرد على
      طلبات دعم المستخدمين، أو (5) حماية حقوق وممتلكات وسلامة اكتفيد
      ومستخدميه
    </li>
    <li>
      <span className='font-bold'>إخلاء الضمانات: </span>
      ينطبق هذا القسم سواء كانت الخدمات المقدمة تحت الموقع مقابل الدفع
      أم لا. قد لا تسمح القوانين المعمول بها بإستثناء بعض الضمانات، لذا
      فإن بعض الإستثناءات المذكورة هنا قد لا تنطبق.
    </li>
    <li>
      يتم توفير الموقع "كما هو" و"حسب التوفر"، وبدون ضمانات من أي نوع
      سواء صريحة أو ضمنية. اكتفيد غير مسؤولة عن جميع الضمانات الصريحة
      والضمنية، بما في ذلك على سبيل المثال لا الحصر، الضمانات الضمنية
      للتسويق والعنوان والملاءمة لغرض معين وعدم الانتهاك وتلك الناشئة عن
      قانون أو من مسار التعامل أو استخدام التجارة. لا يضمن اكتفيد أن
      الموقع سيكون خالياً من الثغرات أو انتهاكات الأمان أو هجمات
      الفيروسات. قد يتعذر الوصول إلى الموقع أحيانًا بسبب الصيانة
      الروتينية أو الترقيات أو أسباب أخرى. توافق على ألا تحمل اكتفيد
      مسؤولية أي عواقب قد تنجم عنك أو عن أي طرف ثالث بسبب مشكلات تقنية
      على الإنترنت أو بطء الاتصالات أو الازدحام.
    </li>
  </ul>`,
  en: `<p>
  Welcome to the Activid website. Please read the following terms of
  use carefully before using this site so that you are aware of your
  legal rights and obligations to Activid.
  <br />
  By accessing or using the site, you expressly acknowledge and agree
  that you are entering into a legal agreement with us and have
  understood and agreed to comply with these terms of use, along with
  the privacy policy (“Terms”).
  <br />
  These Terms are between Activid and you, whether, for example, on
  behalf of your employer or any other entity you represent (“you” or
  “the client”). If you represent an employer or another entity, you
  represent here that
  <br />
  (1) you have full legal authority to bind your employer or this
  entity (as applicable) to these terms of use;
  <br />
  and (2) after reading and understanding these terms, you agree to
  these terms on behalf of your employer or the relevant entity (as
  applicable), and these terms are binding on your employer or the
  relevant entity (as applicable). You hereby waive any applicable
  rights to require an original (non-electronic) signature or delivery
  or retention of non-electronic records, to the extent not prohibited
  by applicable law.
  <br />
  If you do not agree to abide by these Terms, please do not access or
  use the site.
</p>
<ul>
  <li>
    <span className='font-bold'>Amendment: </span>
    We reserve the right, at our discretion, to change these Terms at
    any time. These changes will take effect ten (10) days after
    posting the amended Terms on the site, and your continued use of
    the site thereafter means your acceptance of these changes.
  </li>
  <li>
    <span className='font-bold'>Subscription Fees: </span>
    In consideration for the license granted hereunder, the Client
    shall pay Activid’s fees as specified in the applicable Order
    (“Fees”). “Order” means Activid’s electronic form completed by the
    Client, which lists the requested services, subscription plan,
    term and associated fees. The Order will automatically renew for
    the same subscription period unless either Activid or you
    terminate the subscription prior to the end of the current
    subscription period. Activid reserves the right to change its fees
    at any time and must notify the Client of such changes via email
    prior thereto. If the Client does not agree to a price change, its
    sole remedy is to terminate its applicable Order, which it may do
    within the platform. In case Activid increases its fees and Client
    terminates its applicable Order in accordance with the previous
    sentence without refunding any amount. The Client agrees to
    provide Activid with updated, accurate and complete billing
    information and authorizes Activid either directly or through its
    affiliates or other companies to collect payment or charge
    otherwise or take any other billing actions from its payment
    method and make any inquiries we may consider necessary to verify
    its designated payment account or financial information in order
    to ensure prompt payment, including for receiving updated payment
    details from its credit card company or bank account (such as
    updated expiration date or card number that may be provided to us
    by its credit card company). Unless otherwise stated herein or in
    an Order: Fees are quoted in US dollars All fees and other amounts
    paid under this are non-refundable. All amounts due under these
    Terms are exclusive of all sales, use, value-added, withholding
    taxes and other direct or indirect charges and costs.
  </li>
  <li>
    <span className='font-bold'>Account: </span>
    To use the platform, a Client may need to create an account or use
    an existing account (known as “Account”). The Client must provide
    accurate account information and is solely responsible for
    activity that occurs in its Account. The Client hereby agrees to
    maintain confidentiality of its Account password and immediately
    notify Activid of any unauthorized use of its Account.
  </li>
  <li>
    <span className='font-bold'>Permitted Users: </span>
    Access to the platform is only available to employees or service
    providers who are expressly authorized by the Client to use the
    platform (each a “Permitted User”). The Client will ensure that
    Permitted Users comply with these Terms at all times; and is fully
    responsible for any breach of this Agreement by a Permitted User.
    Unauthorized access or use of the platform must be reported
    immediately to Activid.
  </li>
  <li>
    <span className='font-bold'>Subscription Fees: </span>
    In consideration of the license granted under this Agreement, the
    Customer shall pay the fees set forth in the applicable Order
    (“Fees”) to Activid. “Order” means the electronic form of Activid
    completed by the Customer, which includes a list of requested
    Services, subscription plan, term and associated fees. The Order
    will automatically renew for the same subscription period unless
    either party terminates the subscription prior to the expiration
    of the current subscription period. Activid reserves the right to
    change its fees at any time, and will notify the Customer of such
    changes via email prior to the effective date of such changes
    (“Notice”). In the event that the Customer does not agree to a
    price change, the Customer’s sole remedy is to terminate the
    applicable Order, which it may do via the Platform. In the event
    that Activid increases its fees and the Customer terminates the
    applicable Order in accordance with the preceding sentence,
    Activid will refund a pro rata amount for any unused portion of
    prepaid fees paid by the Customer for the remaining term of the
    subscription. The Customer agrees to provide Activid with
    accurate, complete and up-to-date billing information and
    authorizes Activid (either directly or through its affiliates or
    other companies) to collect payment (or otherwise charge or refund
    or take any other billing actions) from the Customer’s payment
    method and make any inquiries we may consider necessary to
    validate your designated payment account or financial information.
  </li>
  <li>
    <span className='font-bold'>Restrictions: </span>
    Except as expressly permitted in this Agreement, Customer or any
    Authorized User or any third party shall not directly or
    indirectly: (1) copy, modify or create derivative works from any
    part of the Site (including investing in its products); (2) sell
    or license (or sublicense) or lease or transfer or encumber or
    share Customer’s rights under these Terms with any third party;
    (3) use any “open source” or “copyleft” software in a manner that
    would require Activid to disclose source code for the Site.
  </li>
  <li>
    <span className='font-bold'>Intellectual Property Rights: </span>
    <ul>
      <li>
        <span className='font-bold'>Content and Marks: </span>
        (1) Content on the Site, including but not limited to text,
        documents, articles, brochures, products, software, graphics,
        images, sounds, video clips, interactive features and services
        (collectively referred to as “Materials”), and (2) trademarks
        and trade names and logos contained therein (“Marks” and
        together with “Materials”, “Content”) are owned by Activid
        and/or its licensors and may be protected by copyright laws or
        other intellectual property laws and treaties. “Activid”,
        “Activid Logo” and other marks are trademarks of Activid or
        its affiliates. All other trademarks and service marks and
        logos used on the Site are trademarks or trade names or logos
        of their respective owners. We reserve all rights not
        expressly granted in the Site and Content.
      </li>
      <li>
        <span className='font-bold'>Use of Content: </span>
        Content is provided on the Site for User’s information and
        personal use only and may not be used, modified, copied,
        distributed, transmitted, sold, sublicensed, decrypted or
        exploited in any other way for any other purposes whatsoever
        without our prior written consent. If you download or print a
        copy of Content, you must retain all copyright and other
        proprietary notices contained therein.
      </li>
      <li>
        <span className='font-bold'>
          Intellectual Property Rights:{" "}
        </span>
        Any intellectual property rights embodied in and/or associated
        with (and any improvements and derivative works thereof) and
        any other products or deliverables or services provided by
        Activid are and shall remain solely owned by Activid or its
        licensors. These Terms do not transfer to Customer any
        interest in Platform other than right to use Platform pursuant
        to applicable Order. Nothing herein constitutes a waiver of
        Activid’s intellectual property rights under any law.
      </li>
      <li>
        <span className='font-bold'>Analytics: </span>
        Any anonymized information derived from use of Site (i.e.,
        metadata or aggregated information and/or analytics) that does
        not contain personally identifiable information (“Analytics
        Information”) may be used by Activid in connection with
        providing Services under these Terms and for development
        and/or statistical purposes. Such information is proprietary
        to Activid.
      </li>
    </ul>
  </li>
  <li>
    <span className='font-bold'>Overperformance: </span>
    In the event of any problem with Activid’s Services, the full
    liability for any loss or damage suffered by the Customer shall be
    limited to the amount of Fees paid by the Customer to Activid
    under this Order giving rise to the claim.
    <ul>
      <li>
        <span className='font-bold'>Potential Liability: </span>
        In the event of any problem with Activid’s Services, the full
        liability for any loss or damage suffered by the Customer
        shall be limited to the amount of Fees paid by the Customer to
        Activid under this Order giving rise to the claim.
      </li>
      <li>
        <span className='font-bold'>Indemnification: </span>
        The Customer agrees to indemnify Activid and its employees,
        agents, suppliers, officers, directors, agents, affiliates,
        partners and other users of the Site and Services from any
        loss, damage, cost or expense (including reasonable attorneys’
        fees), including without limitation, due to (a) its use of the
        Services; and (b) its breach of any terms of this Agreement.
      </li>
    </ul>
  </li>
  <li>
    <span className='font-bold'>
      Third Party Sources and Content:{" "}
    </span>
    The Site allows you to view and access and link to third party
    content (defined below) that is not owned or controlled by
    “Activid” (“Third Party Content”). The Site may also allow you to
    communicate and interact with third party sources. “Third Party
    Sources” means: (1) third party sites and services; and (2)
    Activid’s partners and customers.
  </li>
  <li>
    <span className='font-bold'>
      We have no affiliation or control over any Third Party Sources:{" "}
    </span>
    We are not responsible for any Third Party Content or terms of use
    or privacy policies or actions or practices of any Third Party
    Sources. Please read the terms of use and privacy policy of any
    Third Party Source you interact with before engaging in any such
    activity.
  </li>
  <li>
    We are not responsible for, and we expressly disclaim liability
    for, the accuracy, suitability, usefulness, safety, or
    intellectual property rights (defined below) of or relating to any
    Third Party Content.
  </li>
  <li>
    We do not endorse any advertisements or promotions or campaigns or
    products or services or other materials included in any Third
    Party Content or communicated to you from a Third Party Source.
    Your interaction with a Third Party Source and your viewing and
    use of Third Party Content and reliance thereon is at your own
    risk and peril.
  </li>
  <li>
    You are solely responsible for your interactions with a Third
    Party Source. You agree to waive and hereby waive any rights or
    remedies you may have against “Activid” and release “Activid” from
    any and all liability arising from your use of Third Party Content
    and your interaction with any Third Party Source. If you have any
    inquiry or complaint regarding a Third Party Source or any Third
    Party Content, you agree to contact the Third Party Source
    directly.
  </li>
  <li>
    <span className='font-bold'>Information Description: </span>
    We try to be as accurate as possible. However, we cannot and do
    not warrant that the Content available on the Site is accurate,
    complete, reliable, current and error-free. We reserve the right
    to make changes to the Content or any part thereof at our sole
    discretion without obligation to notify before or after making
    such changes to the Content. Your use of the Content or any part
    thereof is at your own risk.
  </li>
  <li>
    <span className='font-bold'>Disclosure: </span>
    We reserve the right to access, read, preserve and disclose any
    information we obtain in connection with the Site and your use
    thereof, as we reasonably believe is necessary to (1) comply with
    any applicable law or regulation or legal process or governmental
    request, (2) enforce the Site Terms, including investigation of
    potential violations thereof, (3) detect, prevent or otherwise
    address fraud, security or technical issues, (4) respond to user
    support requests, or (5) protect the rights, property and safety
    of Activid and its users.
  </li>
  <li>
    <span className='font-bold'>Privacy: </span>
    We will use any personal information we may collect or obtain in
    connection with the Site in accordance with our Privacy Policy.
  </li>
  <li>
    <span className='font-bold'>Copyright and Content Policy: </span>
    We reserve the right to access, read, preserve and disclose any
    information we obtain in connection with the Site and your use
    thereof, as we reasonably believe is necessary to (1) comply with
    any applicable law or regulation or legal process or governmental
    request, (2) enforce the Site Terms, including investigation of
    potential violations thereof, (3) detect, prevent or otherwise
    address fraud, security or technical issues, (4) respond to user
    support requests, or (5) protect the rights, property and safety
    of Activid and its users.
  </li>
  <li>
    <span className='font-bold'>Disclaimer of Warranties: </span>
    This section applies whether the Services provided under the Site
    are for payment or not. Applicable law may not allow the exclusion
    of certain warranties, so some of the exclusions set forth herein
    may not apply.
  </li>
  <li>
    The Site is provided “AS IS” and “AS AVAILABLE”, without
    warranties of any kind either express or implied. Activid
    disclaims all warranties, express and implied, including but not
    limited to implied warranties of merchantability, title, fitness
    for a particular purpose and non-infringement and those arising by
    statute or from a course of dealing or usage of trade. Activid
    does not warrant that the Site will be free from bugs or security
    breaches or virus attacks. Access to the Site may occasionally be
    unavailable due to routine maintenance or upgrades or other
    reasons. You agree not to hold Activid liable for any consequences
    that may result from you or any third party due to technical
    problems on the Internet or slow connections or congestion.
  </li>
</ul>`
}