import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import toastifyFile from "../external-libraries/React-toastify/index";
import errorConstants from "../../constants/ErrorConstants";
import AuthHeader from "./AuthHeader";
import axoisConfigUtil from "../../utils/AxoisConfigUtil";

import { useFormik } from "formik";
import * as Yup from "yup";
import {
  InputGroup,
  Input,
  InputRightElement,
  InputLeftElement,
  Stack,
  InputLeftAddon,
  Text,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Tfoot,
  Link,
  Tag,
  Flex,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

import { BsCheck2Circle } from "react-icons/bs";
import { BiError } from "react-icons/bi";
import { BsTwitter } from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io";
import languageConstants from "../../constants/LanguageConstants";
import { useEffect } from "react";
import PlatformsConstants from "../../constants/PlatformsConstants";
import settingsConstants from "../../constants/SettingsConstants";

import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink, LinkProps } from '@chakra-ui/react'

function Signup({ language, setLanguage }) {
  let navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `${t("title.projectName")} | ${t("home.signUp")}`;
  }, []);

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      name: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      terms: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("formik.required")),
      email: Yup.string()
        .email(t("formik.email"))
        .required(t("formik.required")),
      password: Yup.string()
        .required(t("formik.required"))
        .min(8, t("formik.minPassword")),
      passwordConfirmation: Yup.string()
        .required(t("formik.required"))
        .oneOf([Yup.ref("password"), null], t("formik.passwordConfirmation")),
      terms: Yup.boolean().oneOf([true], t("formik.terms")),
    }),
    onSubmit: (values) => {
      signupSubmit();
    },
  });

  function signupSubmit() {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/register`,
        {
          name: formik.values.name,
          email: formik.values.email,
          password: formik.values.password,
          password_confirmation: formik.values.passwordConfirmation,
        },
        axoisConfigUtil.getHeadersWithLang()
      )
      .then(function (response) {
        toastifyFile.successNotify(
          t("signUp.messages.success.accountCreatedSuccessfully")
        );
        toastifyFile.successNotify(
          t("login.messages.success.loggedInSuccessfully")
        );
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        navigate("/dashboard");
      })
      .catch(function (error) {
        if (!error.response) {
          // network error
          toastifyFile.errorNotify(
            t("general.messages.error.connectionRefused") +
            " : " +
            errorConstants.signUpPage.code
          );
        }
        if (error.response.status === 400) {
          if (error.response?.data?.errors?.email) {
            formik.setErrors({ email: error.response.data.errors.email });
          }
          toastifyFile.errorNotify(error.response.data.message);
        } else {
          toastifyFile.errorNotify(
            t("general.messages.error.generalError") +
            " : " +
            errorConstants.signUpPage.code
          );
        }
      });
  }

  return (
    <>
      <div className='min-h-screen pb-5 flex flex-col gap-8 items-center bg-[#ebf7fd] custom-bg text-gray-500'>
        <AuthHeader
          pageType='signup'
          language={language}
          setLanguage={setLanguage}
        />
        <div className='flex flex-col gap-2 p-7 bg-white rounded-tr-2xl rounded-bl-2xl'>
          <Text fontSize='2xl' className='m-2'>
            {t("signUp.noteHeader")}
          </Text>
          <Text fontSize='lg' className='m-2'>
            {t("signUp.noteSubHeader")}
          </Text>
          <TableContainer>
            <Table>
              <TableCaption>
                <Flex gap={2}>
                  {t("signUp.tableCaption")}
                  <BsTwitter
                    className='cursor-pointer'
                    onClick={() => {
                      window.open(`https://twitter.com/ACTIVIDEapp`, "_blank");
                    }}
                  />
                  <IoLogoWhatsapp
                    className='cursor-pointer'
                    onClick={() => {
                      window.open(
                        `https://api.whatsapp.com/send?phone=${settingsConstants.ACTIVID_SUPPORT_NUMBER}&text=اهلا%20,%20عندي%20سؤال`,
                        "_blank"
                      );
                    }}
                  />
                </Flex>
              </TableCaption>
              <Thead>
                <Tr>
                  <Th>{t("signUp.channel")}</Th>
                  <Th>{t("signUp.status")}</Th>
                  <Th>{t("signUp.actions")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>
                    <Link
                      href={PlatformsConstants.salla.landingPage}
                      isExternal
                    >
                      {t("signUp.salla")} <ExternalLinkIcon mx='2px' />
                    </Link>
                  </Td>
                  <Td>
                    <Tag variant='solid' colorScheme='green'>
                      {t("signUp.available")}
                    </Tag>
                  </Td>
                  <Td>
                    <Tag variant='solid' colorScheme='green'>
                      <Link
                        href={PlatformsConstants.salla.loginPage}
                        isExternal
                      >
                        {t("signUp.registerNow")} <ExternalLinkIcon mx='2px' />
                      </Link>
                    </Tag>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Link href={PlatformsConstants.zid.landingPage} isExternal>
                      {t("signUp.zid")} <ExternalLinkIcon mx='2px' />
                    </Link>
                  </Td>
                  <Td>
                    <Tag variant='solid' colorScheme='green'>
                      {t("signUp.available")}
                    </Tag>
                  </Td>
                  <Td>
                    <Tag variant='solid' colorScheme='green'>
                      <Link href={PlatformsConstants.zid.loginPage} isExternal>
                        {t("signUp.registerNow")} <ExternalLinkIcon mx='2px' />
                      </Link>
                    </Tag>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Link >
                      {t("signUp.customWebsite")}
                    </Link>
                  </Td>
                  <Td>
                    <Tag variant='solid' colorScheme='green'>
                      {t("signUp.available")}
                    </Tag>
                  </Td>
                  <Td>
                    <Tag variant='solid' colorScheme='green'>
                      <ChakraLink as={ReactRouterLink} to='/create-store'>
                        {t("signUp.registerNow")} <ExternalLinkIcon mx='2px' />
                      </ChakraLink>
                    </Tag>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
}

export default Signup;
