import { Container } from "@chakra-ui/react";

export default function DashboardHeader({
  activeTab,
  setActiveTab,
  t,
  notAllowedToNavigateTabs,
  navigateToProjectStatistics,
  navigateToProjectDeploy
}) {
  const deployedClass = notAllowedToNavigateTabs
    ? "cursor-not-allowed"
    : "cursor-pointer";
  return (
    <Container
      className='flex justify-around text-xl w-4/5 mx-auto'
      fontSize={{ base: "2xl", lg: "3xl" }}
    >
      <div
        onClick={() => setActiveTab(0)}
        className={`${deployedClass} select-none ${activeTab === 0
          ? "border-b-2 border-b-primary text-primary"
          : "text-slate-400"
          }`}
      >
        {t("userDashboard.building")}
      </div>
      <div
        onClick={() => setActiveTab(1)}
        className={`${deployedClass} select-none ${activeTab === 1
          ? "border-b-2 border-b-primary text-primary"
          : "text-slate-400"
          }`}
      >
        {t("userDashboard.rules")}
      </div>
      <div
        onClick={() => setActiveTab(2)}
        className={`${deployedClass} select-none ${activeTab === 2
          ? "border-b-2 border-b-primary text-primary"
          : "text-slate-400"
          }`}
      >
        {t("userDashboard.design")}
      </div>
      <div
        onClick={() => setActiveTab(3)}
        className={`${deployedClass} select-none ${activeTab === 3
          ? "border-b-2 border-b-primary text-primary"
          : "text-slate-400"
          }`}
      >
        {t("userDashboard.settings")}
      </div>
      <div
        onClick={() => navigateToProjectDeploy()}
        className={`cursor-pointer select-none ${activeTab === 4
          ? "border-b-2 border-b-primary text-primary"
          : "text-slate-400"
          }`}
      >
        {t("userDashboard.deploy")}
      </div>
      <div
        onClick={() => navigateToProjectStatistics()}
        className={`cursor-pointer select-none ${activeTab === 5
          ? "border-b-2 border-b-primary text-primary"
          : "text-slate-400"
          }`}
      >
        {t("userDashboard.statistics")}
      </div>

    </Container>
  );
}
