import logoLoading from "../assets/images/activid_logo_loading.gif";

export default function Loader({ isLoading }) {
  return (
    <>
      {isLoading ? (
        <div className='w-1/4 h-full flex items-center justify-center'>
          <img src={logoLoading} alt='' />
        </div>
      ) : null}
    </>
  );
}
