import { Button } from "@chakra-ui/react";
import { BsCollectionPlayFill } from "react-icons/bs";

export default function EmptyState({ t, fileUploaderDialogOnOpen }) {
  return (
    <>
      <div className='text-center pt-10 text-primary text-2xl font-light'>
        <p>{t("emptyState.greeting")}</p>
        <p>{t("emptyState.startNow")}</p>

        <Button
          onClick={fileUploaderDialogOnOpen}
          leftIcon={<BsCollectionPlayFill />}
          background='#00bcee'
          color='#323232'
          fontWeight='light'
          variant='outline'
          _hover={{
            background: "#00bcee",
          }}
          width='40%'
          marginTop='8'
          fontSize='xl'
          rounded='xl'
        >
          {t("emptyState.createBtn")}
        </Button>
      </div>
    </>
  );
}
