import { ChakraProvider, extendTheme } from "@chakra-ui/react";

function ChakraRTLProvider({ children }) {
  const direction = document.documentElement.lang === "ar" ? "rtl" : "ltr";

  // 👇🏻 Here's the place we add direction to the theme
  const theme = extendTheme({ direction });

  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
}

export default ChakraRTLProvider
