import { useTranslation } from "react-i18next";
import { Navigate, Outlet } from "react-router-dom";
import errorConstants from "../../../constants/ErrorConstants";
import toastifyFile from "../../external-libraries/React-toastify/index";

function AuthDashboard() {
  const hasToken = localStorage.getItem("token") != null;
  const isPhoneScreen = document.body.clientWidth <= 900;

  const { t } = useTranslation();

  function goBack() {
    toastifyFile.errorNotify(
      t("general.messages.error.loginFirst") +
        " : " +
        errorConstants.authDashboard.code
    );
    return <Navigate to={"/login"} />;
  }

  function showNotSupportedPhoneScreen() {
    return <Navigate to={"/not-supported-phone-screen"} />;
  }

  return !isPhoneScreen ? (
    hasToken ? (
      <Outlet />
    ) : (
      goBack()
    )
  ) : (
    showNotSupportedPhoneScreen()
  );
}

export default AuthDashboard;
