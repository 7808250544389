const projectStatusConstants = {
    NOT_COMPLETE:
    {
        key: "NOT_COMPLETE",
        color: "#EAC530",
        arTitle: "غير مكتمل",
        enTitle: "NOT COMPLETE",
        arMessage: "الرجاء إضافة فيديو واحد على الاقل",
        enMessage: "Please add at least one video",
        arButton: "انتقل لصفحة البناء لإضافة فيديو",
        enButton: "Go to the build page to add a video",
    },
    READY:
    {
        key: "READY",
        color: "#60D960",
        arTitle: "جـــاهـــز",
        enTitle: "READY",
        arMessage: " مشروعك جاهز للنشر ، بإمكانك نشره في أي وقت",
        enMessage: "Your project is ready to be deployed, you can deployed it at any time",
        arButton: "انــشــر الان",
        enButton: "Deploy Now",
    },
    DEPLOYED:
    {
        key: "DEPLOYED",
        color: "#00ccff",
        arTitle: "مـنـشـور",
        enTitle: "DEPLOYED",
        arMessage: "تم نشر مشروعك ، يمكنك تعليقه في أي وقت",
        enMessage: "Your project is deployed , you can suspended it at any time",
        arButton: "علقها الآن",
        enButton: "Suspend it Now",
    },
    SUSPENDED:
    {
        key: "SUSPENDED",
        color: "#D01717",
        arTitle: "مـعـلـق",
        enTitle: "SUSPENDED",
        arMessage: "مشروعك معلق حاليا ، تقدر تعيد نشرة في اي وقت",
        enMessage: "Your project is currently suspended, you can re-deploy at any time",
        arButton: "إعادة نشر المشروع",
        enButton: "Re-deploy the Project",
    },
    SUSPENDED_BY_SYSTEM:
    {
        key: "SUSPENDED_BY_SYSTEM",
        color: "#000000",
        arTitle: "مـعـلـق من قبل النظام",
        enTitle: "SUSPENDED BY SYSTEM",
        arMessage: "مشروعك معلق من قبل فريقنا ، إذا كنت تعتقد أن هناك خطأ ما تواصل معنا",
        enMessage: "Your project is suspended by our team, if you think something is wrong contact us",
        arButton: "تواصل معنا",
        enButton: "Connect with us",
    },
    USER_NOT_VERIFIED:
    {
        key: "USER_NOT_VERIFIED",
        color: "#EAC530",
        arTitle: "غير مكتمل",
        enTitle: "NOT COMPLETE",
        arMessage: "الرجاء تفعيل حسابك عن طريق الرسالة المُرسلة على بريدك الالكتروني",
        enMessage: "Please verify your account using the link sent to your email",
        arButton: "اضغط هنا لإرسال رابط التفعيل",
        enButton: "Click here to resend the verification link",
    },
    STOPPED:
    {
        key: "STOPPED",
        color: "#c9bf93",
        arTitle: "لا يوجد إشتراك",
        enTitle: "NO SUBSCRIPTION",
        arMessage: "لا يوجد اشتراك فعال بمتجرك في سلة",
        enMessage: "There is no active subscription to your store at Salla",
        arButton: "جدد اشتاركي عن طريق سلة",
        enButton: "Renew your subscription via Salla",
    },

};

export default projectStatusConstants;