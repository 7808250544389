import { Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Home() {

  const hasToken = localStorage.getItem("token") != null;

  function goBack() {
    return <Navigate to={"/login"} />;
  }

  function goToDashboard() {
    return <Navigate to={"/dashboard"} />;

  }

  return hasToken ? goToDashboard() : goBack();

}

export default Home;
