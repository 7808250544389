import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

function successNotify(message) {
    toast.success(message, {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

function errorNotify(message) {
    toast.error(message, {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

function warnNotify(message) {
    toast.warn(message, {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

const exportedObject = {
    successNotify,
    errorNotify,
    warnNotify
};

export default exportedObject;