import { t } from "i18next";
import AuthHeader from "../auth/AuthHeader";
import {
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Td,
    Tbody,
    Link,
    Tag,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axoisConfigUtil from "../../utils/AxoisConfigUtil";
import axios from "axios";
function CouponUseCount({ language, setLanguage }) {

    const [couponDetails, setCouponDetails] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {

        // console.log(searchParams.get("c"));
        // setCouponDetails({
        //     code: "AZZ20",
        //     usedCount: 23,
        //     stores: [
        //         { name: "store1", subscriptionDate: "2024-04-27", url: "https://v2.chakra-ui.com/docs/components/table/usage" },
        //         { name: "store2", subscriptionDate: "2024-04-27", url: "https://v2.chakra-ui.com/docs/components/table/usage" },
        //         { name: "store3", subscriptionDate: "2024-04-27", url: "https://v2.chakra-ui.com/docs/components/table/usage" },
        //     ],
        // })

        axios
            .get(
                `${process.env.REACT_APP_API_URL}/coupon/${searchParams.get("c")}`,
                axoisConfigUtil.getHeadersWithLang()
            )
            .then((res) => {
                setCouponDetails(res.data)
            })
            .catch((error) => {
                console.log(error);
            });



    }, [])
    return (

        <div className='min-h-screen flex flex-col gap-8 items-center bg-[#ebf7fd] custom-bg text-gray-500'>
            <AuthHeader
                pageType='login'
                language={language}
                setLanguage={setLanguage}
            />
            <div className='md:w-4/5 w-11/12 '>
                <div className='flex flex-col gap-2 p-7 bg-white rounded-tr-2xl rounded-bl-2xl text-center'>

                    <p className='mb-4 text-2xl w-full md:text-center'>
                        {t("couponUseCount.coupon")} : <Tag variant='solid' colorScheme='green' verticalAlign={'baseline'}>{couponDetails.code}</Tag>
                    </p>
                    <p className='mb-4 text-2xl w-full md:text-center'>
                        {t("couponUseCount.numberOfUsage")} : <Tag variant='solid' colorScheme='green' verticalAlign={'baseline'}>{couponDetails.usedCount}</Tag>
                    </p>
                    <p className='mb-4 mt-4 text-3xl w-full md:text-center'>
                        {t("couponUseCount.stores")}
                    </p>

                    <TableContainer>
                        <Table size='sm'>
                            <Thead>
                                <Tr>
                                    <Th>/</Th>
                                    <Th>{t("couponUseCount.store")}</Th>
                                    <Th>{t("couponUseCount.subscriptionDate")}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {couponDetails?.stores?.map((ele, index) => {
                                    return <Tr key={index}>
                                        <Td>
                                            {index}
                                        </Td>
                                        <Td>
                                            <Link href={ele.url} isExternal>
                                                {ele.name} <ExternalLinkIcon mx='2px' />
                                            </Link>
                                        </Td>
                                        <Td>
                                            {ele.subscriptionDate}
                                        </Td>
                                    </Tr>
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>

                </div>

            </div>
        </div>
    );
}

export default CouponUseCount;