import sallaIcon from "../assets/images/dashboard/platforms/salla/salla-icon.png";
import zidIcon from "../assets/images/dashboard/platforms/zid/zid-icon.png";
import actividIcon from "../assets/images/dashboard/platforms/activid/activid-icon.png";

const PlatformsConstants = {
  salla: {
    enName: "Salla",
    arName: "سلة",
    platformIdentify: "10",
    icon: sallaIcon,
    landingPage: "https://salla.com/",
    loginPage: "https://apps.salla.sa/ar/app/1770758172",
  },
  zid: {
    enName: "Zid",
    arName: "زد",
    platformIdentify: "20",
    icon: zidIcon,
    landingPage: "https://zid.sa/",
    loginPage: "https://apps.zid.sa/application/2703",
  },
  activide: {
    enName: "activide",
    arName: "أكتيڤيد",
    platformIdentify: "30",
    icon: actividIcon,
    landingPage: "https://www.acti-vide.com/",
    loginPage: "https://app.acti-vide.com/login",
  },
};

export default PlatformsConstants;
