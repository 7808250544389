const errorConstants = {
    loginPage: { componentName: "login", code: "#00001" },
    signUpPage: { componentName: "Signup", code: "#00002" },
    authDashboard: { componentName: "AuthDashboard", code: "#00003" },
    authAdminDashboard: { componentName: "AuthAdminDashboard", code: "#00004" },
    resetPassword: { componentName: "ResetPassword", code: "#00005" },
    newPassword: { componentName: "NewPassword", code: "#00006" },
    sendVerifyMail: { componentName: "SendVerifyMail", code: "#00007" },
    salla: { componentName: "SallaWelcoming", code: "#00008" },
    projectDeploy: { componentName: "ProjectDeploy", code: "#00009" },
    axiosInterceptors: { componentName: "AxiosInterceptors", code: "#00010" },
    projectItem: { componentName: "ProjectItem", code: "#00011" },
};

export default errorConstants;